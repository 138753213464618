import angular from "angular";

/**
 * App initialization functions
 */
inits.$inject = [
  "$rootScope",
  "$window",
  "User",
  "Analytics",
  "$location",
  "device",
  "PageStore",
  "UserStorage",
  "flux",
];
export function inits(
  $rootScope,
  $window,
  User,
  Analytics,
  $location,
  device,
  PageStore,
  UserStorage,
  flux,
) {
  const $ = angular.element;
  const body = $("body");

  /**
   * helper to set the page vars iff its not the initial page load
   * initial page load would have these embedded on the page already.
   * In some cases there is nothing embedded on page, so force it to
   * set even if its an initial page load.
   */
  function controllerWillRender(options, force) {
    if (force || !PageStore.isInitialPage()) {
      if (options.removeServerSideContent) {
        flux.dispatch("remove-server-side-content", {});
      }
      if (options.objectTools) {
        flux.dispatch("set-object-tools", options.objectTools);
      }
      body.attr("id", options.bodyId || "agent");
    }

    if (options.pageStore) {
      flux.dispatch("set-page-store", options.pageStore);
    }
  }

  return {
    /**
     * controllerWillRender
     *
     * Not actually an init, its a helper
     *
     * Call this after the controller scope has been populated
     * and the view is about to render to DOM.
     *
     * options:
     *  removeServerSideContent {bool}
     *  objectTools {array}
     *  pageStore {object}
     *  bodyId {string}
     */
    controllerWillRender: controllerWillRender,

    /**
     * Watch rootScope for routeChangeStart events,
     * use angular router ONLY if going from one search page to another.
     *
     * All other requests are done normally with the browser loading from the server.
     * This is usually much faster anyway now that there is a CDN and caching.
     *
     */
    ngRouteOnlyForSearchPageCtrl: function () {
      $rootScope.$on("$routeChangeStart", function (event, next, current) {
        // if !current then is initial page load
        if(!current ) {
          // console.log("ngRouteOnlyForSearchPageCtrl: no current route, initial page load");
          return;
        }
        const currentController = current?.$$route?.controller;
        const nextController = next?.$$route?.controller;
        const goingBetweenSearchPages = currentController === "SearchPageCtrl" && nextController === "SearchPageCtrl";
        if (!goingBetweenSearchPages) {
          // Let the browser handle the route change, not angular
          event.preventDefault();
          $window.location.href = $location.absUrl();
        }
      });
    },

    /**
     *  nestseekers app specific setup of analytics
     */
    analytics: function () {
      if (User.isUser()) {
        User.get(function (user) {
          Analytics.identify({
            uid: user.uid,
            email: user.email,
            name: user.name,
            is_staff: user.is_staff,
          });
        });
      }

      // log analytics on any routeChange
      $rootScope.$on("$routeChangeSuccess", function (event, current) {
        const r = current.$$route;
        const url = $location.absUrl();
        if (r) {
          Analytics.googlePageView(url);

          // track analytics if a .track slug is set on the route
          if (r.track) {
            Analytics.track(r.track);
          }
        }
      });

      /**
       * user searched or filtered their search results
       */
      function trackSearch() {
        Analytics.track("search", {
          path: $location.path(),
        });
      }

      flux.onAction("track-page", function (url) {
        Analytics.googlePageView(url);
      });

      flux.onChanged(
        "search-is-searching",
        function (bool) {
          if (bool) {
            trackSearch();
          }
        },
        $rootScope
      );
    },

    /**
     * Watches the size of the inner user content
     * and sets PageStore pageSize
     */
    onResize: function () {
      let pageSize;

      // check and cache device.screenSize now before any layout changes
      device.screenSize();

      function didResize() {
        let m,
          // if user menu is open
          w = device.clientWidth() - (PageStore.get("userMenu") ? 160 : 0),
          ps;

        if (w >= 836) {
          m = "large";
        } else if (w >= 620) {
          m = "medium";
        } else {
          m = "small";
        }

        ps = m + "-page";
        if (ps !== pageSize) {
          pageSize = ps;
          flux.dispatch("set-page-store", {
            pageSize: pageSize,
          });
        }
      }
      didResize();

      device.onResize(didResize);
    },
    /**
     * Angular and Django don't always get along.
     *
     * Django sometimes uses forms with no action set or
     * action="." meaning to submit the form to the current URL.
     *
     * Angular considers forms with no action to be javascript
     * app interfaces.
     *
     * This sets the action explicitly to the full window.location
     * so Angular leaves them alone.
     */
    ngVsDjango: function () {
      // dammit, angular is eating the django forms
      $("form").each(function (i, form) {
        // any form with a blank action is assumed to be
        // an angular interface element
        const $form = $(form);
        const action = $form.attr("action");
        if (!action || action === "#" || action === ".") {
          // set the action to the current URL
          $form.attr("action", $window.location.pathname);
        }
      });
    },
    /**
     * Initialize PageStore with current page title.
     * Update page title when PageStore is updated (ajax navigation)
     */
    pageVars: function () {
      // load initial values without triggering a changed
      // which is it ??
      const $title = $("title");
      PageStore.set("pageTitle", $title.text() || "");
      PageStore.set("title", $("h1.title").text() || "");

      // for CKEDITOR text editor to find the URLs for skins etc.
      $window.CKEDITOR_GETURL = function (url) {
        return __FRONTEND__ + url;
      };

      // update page title when changed
      flux.onChanged(
        "PageStore",
        function (key) {
          if (key === "pageTitle") {
            $title.text(PageStore.get("pageTitle"));
          }
        },
        $rootScope
      );
    },
    /**
     * Do all functions pushed to window.jsready
     *
     * Django pages may set `window.jsready = [fn1, fn2]`
     * to request that these functions are run after jQuery is
     * loaded and initialiazed.
     *
     * This is because jquery is initiatilized at the bottom of the page
     * and isnt' ready for inline usage
     */
    jsReady: function () {
      angular.forEach(window.jsready, function (f) {
        f();
      });
    },
    /**
     * Initialize app for the current User.
     */
    user: function () {
      UserStorage.setVersion(body.data("version"));
      if ($window.bugsense && User.isUser()) {
        $window.bugsense.addExtraData("uid", User.userId());
      }
    },
  };
}
