/**
 * a LRU cache for page fetches
 *
 * Used to save the last 10 apartment or search requests
 * so that they can be reloaded instantly if the person goes back
 * to those pages.
 */
lruCache.$inject = ["$cacheFactory"];
export function lruCache($cacheFactory) {
  return $cacheFactory("lru-cache", { number: 10 });
}
