import angular from "angular";
import forEach from "lodash/forEach";
import map from "lodash/map";
import shuffle from "lodash/shuffle";
import without from "lodash/without";

const tpl = require("./nsCarousel.html");

nsCarousel.$inject = ["$timeout", "$interval", "imgTools", "$sce", "device"];

/**
 * ns-carousel
 *
 * images for frontpage gallery
 * not used anymore
 *
 */
export function nsCarousel($timeout, $interval, imgTools, $sce, device) {
  return {
    scope: {},
    replace: true,
    templateUrl: tpl,
    link: function ($scope, $element, $attr) {
      let images = JSON.parse($attr.slides),
        big = device.clientWidth() > 640,
        ar = 2200 / 900,
        first,
        cycling = false,
        timers = [];

      function removeTimer(t) {
        timers = without(timers, t);
      }

      if (!big) {
        // Set a random background
        // /static/nestseekers/wwwimages/w/mobile-bg-[0-6].jpg
        const hour = new Date().getHours() % 7;
        angular
          .element(".fp__regions-nav")
          .css(
            "background-image",
            'url("/static/nestseekers/wwwimages/w/mobile-bg-' + hour + '.jpg")'
          )
          .addClass("loaded");
        return;
      }

      $scope.slides = shuffle(
        map(images, function (im) {
          const url = im.l; // big ? im.l : im.s;
          im.url = $sce.trustAsResourceUrl(url);
          return im;
        })
      );

      if (images.length) {
        first = $scope.slides[0].l;
        $scope.slides[0].active = true;
      }

      function calcHeight() {
        const cw = device.clientWidth(),
          isMobile = cw < 753;
        // console.log('isMobile', isMobile, cw, 'setting height:', isMobile ? 0 : Math.min(cw, 1600) / ar);
        // console.log($element);  //carousel inner
        $element.css({ height: isMobile ? 0 : Math.min(cw, 2000) / ar });
        return !isMobile;
      }

      function cycleImages() {
          const $active = $element.find(".active");

          const $next =
            $active.next().length > 0
              ? $active.next()
              : $element.find(".slide-img:first");

          const // .find('.slide-img'),
          img = $next;

          const url = img.data("imgsrc");

          const nf = function () {
            // move the next image up the pile
            $next.css("z-index", 2);

            $active.addClass("fade-out");
            const t = $timeout(function () {
              removeTimer(t);
              // move to bottom of pile and unhide the image
              $active
                .css("z-index", 1)
                .removeClass("fade-out")
                .removeClass("active");
              // make the next image the top one
              $next.css("z-index", 3).addClass("active");
            }, 4000);

            timers.push(t);
          };

          if (img.attr("src") !== url) {
            imgTools.loadImg(url, {}, function () {
              img.attr("src", url);
              nf();
            });
          } else {
            nf();
          }
      }

      function start() {
        const slide = $element
          .find(".slide-img:first")
          .addClass("fade-in")
          .css("z-index", 3);
        let interval;
        cycling = true;
        if (!first) {
          return;
        }

        imgTools.loadImg(first, {}, function () {
          slide.attr("src", first).addClass("loaded");
          interval = $interval(cycleImages, 3000);

          const off = $scope.$on("$locationChangeSuccess", function () {
            $interval.cancel(interval);
            interval = null;
            forEach(timers, (t) => {
              $timeout.cancel(t);
            });
            timers = [];
            off();
          });
        });
      }

      if (calcHeight()) {
        $timeout(start, 1);
      }
      // remove the inline css that keeps the carousel at the correct
      // height and switch to programmatic control
      angular.element("#carousel").css("padding-bottom", 0);
      // show region nav
      $timeout(function () {
        angular.element(".fp__regions-nav").addClass("loaded");
      }, 1000);

      device.onResize(function () {
        if (calcHeight() && !cycling) {
          start();
        }
      }, $scope);
    },
  };
}
