import angular from "angular";
import throttle from 'lodash/throttle';
import find from 'lodash/find';

/**
 * lazyLoad service
 *
 * Sets the src for any "".rthumb img" in $element
 * and does responsive switching between small/large thumb.
 *
 * Binds to window scroll events and flux "PageStore.pageSize" changes.
 */
lazyLoad.$inject = ["flux", "PageStore", "$window", "$rootScope"];

export function lazyLoad(flux, PageStore, $window, $rootScope) {
  const $ = angular.element;
  const $$window = $($window);
  const threshold = 600;

  let started = false;
  // BUG: should reset windowHeight on device resize
  const windowHeight = $window.innerHeight
    ? $window.innerHeight
    : $$window.height();

  const api = {
    start: function () {
      if (!started) {
        $$window.on("scroll", throttle(api.update, 100));

        flux.onChanged(
          "PageStore",
          function (key) {
            if (key === "pageSize") {
              // would have to force ignore of .loaded
              api.update();
            }
          },
          $rootScope
        );

        started = true;
      }
    },
    /**
     * set src on the first {limit} thumbs in element
     */
    setSrcInElement: function ($element, limit) {
      const copyFrom =
        PageStore.get("pageSize") !== "small-page"
          ? "data-large"
          : "data-small";
      find($element.find(".rthumb img"), function (img, i) {
        const $img = $(img);
        if (!$img.data("loaded")) {
          const src = $img.attr(copyFrom);
          if (src !== $img.attr("src")) {
            $img.attr("src", src);
            $img.data("loaded", true);
          }
          return i === limit - 1;
        }
      });
    },
    /**
     * update any on rthumb page in viewport
     */
    update: function () {
      const copyFrom =
        PageStore.get("pageSize") !== "small-page"
          ? "data-large"
          : "data-small";
      const fold = windowHeight + $$window.scrollTop();

      find($(".rthumb").find("img"), function (img) {
        const $img = $(img);
        if (!$img.data("loaded")) {
          const isAbove = $img.offset().top - threshold <= fold;
          // console.log(i, isAbove);
          if (isAbove) {
            const src = $img.attr(copyFrom);
            if (src !== $img.attr("src")) {
              $img.attr("src", src);
              $img.data("loaded", true);
            }
          }
          // stop when you reach bottom of screen
          return !isAbove;
        }
      });
    },
  };

  return api;
}
