innerTitle.$inject = ["flux", "PageStore"];

/**
 * inner title
 * The H1 title at the top of the content area.
 */
export function innerTitle(flux, PageStore) {
  return {
    scope: {
      title: "=innerTitle",
    },
    template: '<h1 class="title" ng-bind="title" ng-show="title" />',
    replace: true,
    link: function ($scope) {
      flux.onChanged(
        "PageStore",
        function (key) {
          if (key === "innerTitle") {
            $scope.title = PageStore.get("innerTitle");
          }
        },
        $scope
      );
    },
  };
}
