lazyloading.$inject = ["lazyLoad"];

/**
 * lazyloading
 *
 * Activates lazy loading of images. See the lazyLoad service.
 *
 * This is applied to parent elements containing multiple '.rthumb img'.
 *
 * 	<div lazyloading>{{ browser.render_results }}</div>
 */
export function lazyloading(lazyLoad) {
  return {
    link: function ($scope, $element) {
      lazyLoad.start();
      // preload the first 10
      lazyLoad.setSrcInElement($element, 10);
    },
  };
}
