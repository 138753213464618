imgNoMobile.$inject = ["device"];
export function imgNoMobile(device) {
  return {
    scope: {},
    replace: true,
    template: '<img ng-src="{{src}}" ng-show="src" />',
    link: function ($scope, $element, $attr) {
      if (!device.isPhone()) {
        $scope.src = $attr.src;
      }
    },
  };
}
