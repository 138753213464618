loginButton.$inject = ["User", "ModalLogin"];

/**
 * login-button
 *
 * if user, show a link to home
 * if not a user, link to "login/signup"
 *
 * and launch the ModalLogin when clicked
 * which uses the global jquery $ that bootstrap needs.
 */
export function loginButton(User, ModalLogin) {
  return {
    scope: {},
    template:
      '<div><a ng-hide="user" ng-click="modalLogin()" trk="nav-signup-button">Register / Sign In</a><a ng-show="user" href="/home"><i class="fa fa-home"></i></a></div>',
    replace: true,
    link: function ($scope) {
      $scope.user = User.isUser();
      $scope.modalLogin = ModalLogin.open;
    },
  };
}
