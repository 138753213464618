const tpl = require("./optionCheckbox.html");

/**
 * option-checkbox
 *
 * Material paper style checkbox
 *
 * Used in checkboxFilter
 *
 * Style is in _paper-checkbox.scss
 */
export function optionCheckbox() {
  return {
    scope: {
      pk: "@",
      caption: "@",
      prefix: "@",
      onChange: "=",
      initial: "=",
    },
    templateUrl: tpl,
    link: function ($scope, $element) {
      $scope.selected = $scope.initial;
      $scope.elId = $scope.prefix + "-" + $scope.pk;
      $scope.click = function ($event, way) {
        $event.stopPropagation();
        $event.preventDefault();
        if ($scope.selected !== way) {
          // find the first span which is our circle/bubble
          const el = $element.find("span.first").eq(0);
          // this has stopped displaying changes
          // happening occassionally

          // add the bubble class (we do this so it doesnt show on page load)
          el.addClass("circle");

          // clone it
          const newone = el.clone(true);

          // add the cloned version before our original
          el.before(newone);

          // remove the original so that it is ready to run on next click
          // $("." + el.attr("class") + ":last").remove();
          el.remove();

          $scope.selected = way;
          if ($scope.onChange) {
            $scope.onChange($scope.pk, way);
          }
        }
      };
    },
  };
}
