import isString from 'lodash/isString';

import isEmpty from 'lodash/isEmpty';

gmapOne.$inject = ["$window", "GoogleMaps", "$timeout", "$log"];

/**
 * directive: gmap-one
 *
 * make a google map with one marker
 *
 * geo="json data"
 * mode="map|bird|street"
 * id="map"  map css id: ie. #map
 */
export function gmapOne($window, GoogleMaps, $timeout, $log) {
  return {
    scope: {
      geo: "@",
    },
    replace: true,
    template: `
      <div id="mapWrap" class="gmap-one nsan-toggle-fade" ng-show="hasGeo">
        <ul class="stepTabs noprint">
          <li class="map" ng-click="map()" ng-class="{selected: which == 'map'}"><span>map</span></li>
          <li class="bird" ng-click="bird()" ng-class="{selected: which == 'bird'}"><span>bird's eye</span></li>
          <li class="street" ng-click="street()" ng-class="{selected: which == 'street'}" ><span>street view</span></li>
        </ul>
        <div id="map"></div>
      </div>`,
    link: function ($scope, $element, $attr) {
      const context = {},
        cssId = "map";

      $scope.hasGeo = false;

      function focus(which) {
        $scope.which = which;
        if (context.sv) {
          context.sv.setVisible(which === "street");
        }
      }

      $scope.map = function () {
        if (context.g) {
          context.gmap.setMapTypeId(context.g.MapTypeId.ROADMAP);
          focus("map");
        }
      };
      $scope.bird = function () {
        if (context.g) {
          context.gmap.setMapTypeId(context.g.MapTypeId.HYBRID);
          context.gmap.setTilt(0);
          focus("bird");
        }
      };
      $scope.street = function () {
        if (context.g) {
          context.gmap.setMapTypeId(context.g.MapTypeId.ROADMAP);
          if (context.sv) {
            context.sv.setPosition($scope.geo.position);
          } else {
            context.sv = new context.g.StreetViewPanorama(
              $window.document.getElementById(cssId),
              {
                position: new context.g.LatLng(
                  context.geo.lat,
                  context.geo.lon
                ),
                pov: {
                  heading: 34,
                  pitch: 10,
                  zoom: 1,
                },
              }
            );
            context.gmap.setStreetView(context.sv);
          }
          focus("street");
        }
      };

      function getGeo() {
        let geo = $scope.geo;
        if (geo && isString(geo)) {
          try {
            geo = JSON.parse(geo);
          } catch (e) {
            $log.error(e);
            return;
          }
        }
        $scope.hasGeo = !isEmpty(geo) && geo.accuracy;
        if (!$scope.hasGeo) {
          $log.debug("Empty geo data");
          return;
        }
        return geo;
      }

      function showMap(geo) {
        let mode;
        context.geo = geo;
        mode = $attr.mode || geo.mode || "map";
        // note: cannot dynamically change geo
        GoogleMaps.maps().then(function (google_maps) {
          context.g = google_maps;
          // this would kill the custom styles for v3.13
          // context.g.visualRefresh = true;
          if (!context.g.LatLng) {
            // something is wrong with google maps loading
            // abort so we don't break other parts of the view
            $log.error("GoogleMaps failed to init");
            $scope.hasGeo = false;
            return;
          }

          const opts = {
            center: new context.g.LatLng(geo.lat, geo.lon),
            zoom: geo.zoom,
            styles: [
              {
                featureType: "all",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    saturation: 36,
                  },
                  {
                    color: "#333333",
                  },
                  {
                    lightness: 40,
                  },
                ],
              },
              {
                featureType: "all",
                elementType: "labels.text.stroke",
                stylers: [
                  {
                    visibility: "on",
                  },
                  {
                    color: "#ffffff",
                  },
                  {
                    lightness: 16,
                  },
                ],
              },
              {
                featureType: "all",
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "all",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#fefefe",
                  },
                  {
                    lightness: 20,
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    color: "#fefefe",
                  },
                  {
                    lightness: 17,
                  },
                  {
                    weight: 1.2,
                  },
                ],
              },
              {
                featureType: "landscape",
                elementType: "geometry",
                stylers: [
                  {
                    lightness: 20,
                  },
                  {
                    color: "#ececec",
                  },
                ],
              },
              {
                featureType: "landscape.man_made",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                  {
                    color: "#f0f0ef",
                  },
                ],
              },
              {
                featureType: "landscape.man_made",
                elementType: "geometry.fill",
                stylers: [
                  {
                    visibility: "on",
                  },
                  {
                    color: "#f0f0ef",
                  },
                ],
              },
              {
                featureType: "landscape.man_made",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    visibility: "on",
                  },
                  {
                    color: "#d4d4d4",
                  },
                ],
              },
              {
                featureType: "landscape.natural",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                  {
                    color: "#ececec",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                  {
                    lightness: 21,
                  },
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "geometry.fill",
                stylers: [
                  {
                    visibility: "on",
                  },
                  {
                    color: "#d4d4d4",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#303030",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "labels.icon",
                stylers: [
                  {
                    saturation: "-100",
                  },
                ],
              },
              {
                featureType: "poi.attraction",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "poi.business",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "poi.government",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "poi.medical",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#dedede",
                  },
                  {
                    lightness: 21,
                  },
                ],
              },
              {
                featureType: "poi.place_of_worship",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "poi.school",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "poi.school",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    lightness: "-61",
                  },
                  {
                    gamma: "0.00",
                  },
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "poi.sports_complex",
                elementType: "all",
                stylers: [
                  {
                    visibility: "on",
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                  {
                    lightness: 17,
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                  {
                    lightness: 29,
                  },
                  {
                    weight: 0.2,
                  },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                  {
                    lightness: 18,
                  },
                ],
              },
              {
                featureType: "road.local",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                  {
                    lightness: 16,
                  },
                ],
              },
              {
                featureType: "transit",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#f2f2f2",
                  },
                  {
                    lightness: 19,
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#dadada",
                  },
                  {
                    lightness: 17,
                  },
                ],
              },
            ],
          };

          context.gmap = GoogleMaps.makeMap(context.g, cssId, opts, mode);
          context.icons = GoogleMaps.icons(context.g);

          focus(($scope.which = mode));
          GoogleMaps.addApt(context, geo, false, null, {});
        });
      }

      const geo = getGeo();
      if (geo) {
        showMap(geo);
      } else {
        // geo not set when inside view-apt
        // must watch for it
        $scope.$watch("geo", function () {
          const g = getGeo();
          if (g) {
            // after DOM is rendered so the div has a size
            $timeout(function () {
              showMap(g);
            }, 50);
          }
        });
      }
    },
  };
}
