import angular from "angular";
import forEach from "lodash/forEach";
import extend from "lodash/extend";
import omit from "lodash/omit";
import map from "lodash/map";
import assign from "lodash/assign";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import find from "lodash/find";

/**
 * Menu service for the primary menu and navbar
 *
 * This stores the state and has actions to change state
 * like openMenu.
 *
 * See navSheet directive which renders the view
 */
Menu.$inject = [
  "flux",
  "$rootScope",
  "$location",
  "$interval",
  "$timeout",
  "device",
  "User",
];
export function Menu(
  flux,
  $rootScope,
  $location,
  $interval,
  $timeout,
  device,
  User
) {
  // not working with jqLite yet
  const $ = angular.element;
  const body = $("body");

  function relativeUrl(url) {
    if (
      url.match(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
      )
    ) {
      return url;
    } else {
      const relative = url.match(/:\/\/[^/]+(\/.+)$/);
      return relative ? relative[1] : url;
    }
  }

  class _Menu {
    constructor() {
      this.state = {};
      this.lastMouse = null;
      this.isTouch = null;

      this.navSheet = null;
      this.navbar = null;
      this.initFlux();
    }

    initFlux() {
      flux.onAction("openMenu", (action) => {
        this.openMenu(action.menu, action.section);
      });

      flux.onAction("closeMenu", () => {
        this.closeMenu();
      });

      flux.onAction("toggleMenu", (action) => {
        // may be switching menus
        if (this.state.open && this.state.menu === action.menu) {
          this.closeMenu();
        } else {
          this.openMenu(action.menu);
        }
      });

      flux.onAction("gotoPath", (action) => {
        const rel = relativeUrl(action.url);
        $location.path(rel);
        $location.search({});
      });

      flux.onChanged(
        "PageStore",
        (key) => {
          if (key === "pageSize" && this.state.open) {
            this.closeMenu();
          }
        },
        $rootScope
      );

      $rootScope.$on("$locationChangeStart", (e, newUrl) => {
        this.closeMenu();
        this.guessSection(relativeUrl(newUrl));
      });
    }

    /**
     * API to openMenu
     */
    openMenu(menu, section) {
      // if no section then default to the last one you had open
      // this only works if you persist it to localstorage
      // or its page in the angular router.
      // if (!section && (menu === this.state.menu)) {
      //   section = this.state.section;
      //   // better is to default the section
      //   // this.guessSection(relativeUrl(newUrl));
      // }
      if (User.isUser() && !this.userMenu) {
        this.loadUserMenu().then(() => {
          this._openMenu(menu, section);
        });
      } else {
        this._openMenu(menu, section);
      }
    }

    isOpen() {
      return this.state.open;
    }

    /**
     * Implements actually opening the menu
     * and/or navigate to a new menu / section
     *
     * @param {String} menu - main|user
     * @param {String} section - 'properties', 'company' etc.
     */
    _openMenu(menu, section) {
      if (!this.state.open) {
        // if it won't be a modal nav sheet,
        // then watch for clicks
        if (!device.isPhone()) {
          this.watch();
        }
      }

      const newState = {
        open: true,
      };

      if (menu) {
        newState.menu = menu;
      }

      if (section) {
        newState.section = section;
      }

      this.setState(newState);
    }

    /**
     * update some values in state
     * broadcasting flux.changed
     */
    updateState(newValues) {
      let newState = extend({}, this.state, newValues);
      if (!newValues.section) {
        // change undefined to unset
        newState = omit(newState, "section");
      }
      this.setState(newState);
    }

    /**
     * set a new state if it has changed from current
     * and broadcast flux.changed
     */
    setState(newState) {
      if (!isEqual(this.state, newState)) {
        this.state = newState;
        flux.changed("Menu", this.state);
      }
    }

    closeMenu() {
      if (this.state.open) {
        this.updateState({ open: false });
        this.unwatch();
        this.guessSection($location.path());
      }
    }

    /**
     * based on current path, find the matching section
     * might send changed
     */
    guessSection(path) {
      let pathSection = path.split("/")[1],
        section,
        sectionLink;
      if (!pathSection) {
        return;
      }
      // Sales/Rentals/NewDevelopments/OpenHouses are in properties
      if (
        pathSection.match(/Sales|Rentals|Properties|OpenHouses/)
      ) {
        section = "properties";
      } else {
        sectionLink = find(this.getSections("main"), (s) => {
          return s.href.indexOf(pathSection) !== -1;
        });
        if (sectionLink && sectionLink.text) {
          section = sectionLink.text.toLowerCase();
        }
      }

      this.updateState({ section: section });
    }

    /**
     * for the modal nav-sheet
     * get a list of links for the current menu + section
     * current section/page is marked selected: true
     */
    getLinks() {
      const here = $location.path();
      return map(this._getRawLinks(), (link) => {
        if (link.href === here) {
          return assign({ selected: true }, link);
        }
        // if its a header then is href in one of the children ?
        return link;
      });
    }

    _getRawLinks() {
      if (!this.state.section) {
        return this.getSections(this.state.menu);
      }

      return this.getPages(this.state.menu, this.state.section);
    }

    /**
     * make links from a jquery
     */
    _makeLinks($els, isHeader, menu) {
      return map($els, (link) => {
        return {
          href: relativeUrl(link.href),
          text: link.text,
          header: isHeader,
          menu: menu,
        };
      });
    }

    /**
     * get sections for main menu or user menu
     */
    getSections(menu) {
      if (menu === "main") {
        if (!this._sectionLinks) {
          this._sectionLinks = this._makeLinks(
            $("#main-menu .nav a"),
            true,
            "main"
          );
        }
        return this._sectionLinks;
      }

      if (menu === "user") {
        return this.userMenu.sections;
      }
      // 'properties'
    }

    /**
     * get links for one section
     */
    getPages(menu, section) {
      const lcaseSection = (section || "").toLowerCase();

      switch (menu) {
        case "main":
          // properties is different
          if (lcaseSection === "properties") {
            return this.getProperties();
          }
          // read it from the server side generated DOM
          return this._makeLinks(
            $("#" + lcaseSection + "_nav").find("a"),
            false,
            "main"
          );
        case "user":
          return this.userMenu.sectionItems[lcaseSection] || [];
        case "tools":
          return [];
      }
    }

    /**
     * properties section design is a table
     */
    getProperties() {
      return map($("#properties_nav > div"), (div) => {
        const $div = $(div);
        return {
          header: this._makeLinks($div.find("strong a"))[0],
          links: this._makeLinks($div.find("ul a")),
        };
      });
    }

    /**
     * Record that the user moved the mouse.
     * This is called also by the navbar.
     */
    mouseIsActive(event) {
      this.lastActive = event.timeStamp;
      this.lastMouse = event;
      this.sniffMouse(event);
    }

    /**
     * detect if device is touch
     * no other good way to do it
     */
    sniffMouse(event) {
      if (this.isTouch === null) {
        if (event.type.indexOf("touch") !== -1) {
          this.isTouch = true;
        }
      }
    }

    /**
     * convert User data into links for menu
     */
    loadUserMenu() {
      return User.fetch().then((data) => {
        this.convertUserMenu(data);
      });
    }

    convertUserMenu(data) {
      this.userMenu = {
        staff: data.is_staff,
      };

      if (data.is_staff) {
        this.userMenu.sections = map(data.items, (item) => {
          return {
            text: item.title,
            href: relativeUrl(item.url),
            icon: item.icon,
            header: !isEmpty(item.items),
            menu: "user",
          };
        });
        this.userMenu.sectionItems = {};
        forEach(data.items, (item) => {
          this.userMenu.sectionItems[item.title.toLowerCase()] = map(
            item.items,
            (it) => {
              if (it.items) {
                this.userMenu.sectionItems[it.title.toLowerCase()] = map(
                  it.items,
                  (it2) => {
                    return {
                      text: it2.title,
                      href: relativeUrl(it2.url),
                      icon: it2.icon,
                      header: !isEmpty(it2.items),
                    };
                  }
                );
              }
              return {
                text: it.title,
                href: relativeUrl(it.url),
                icon: it.icon,
                header: !isEmpty(it.items),
                menu: "user",
              };
            }
          );
        });
      } else {
        // client has one flat list of items
        this.userMenu.sections = map(data.items[0].items, (item) => {
          return {
            text: item.title,
            href: relativeUrl(item.url),
            icon: item.icon,
            header: false,
            menu: "user",
          };
        });
        this.userMenu.sectionItems = {};
      }
    }

    /**
     * for watching click and mouseover events to auto-close the nav-sheet
     */
    onElsewhere(event) {
      this.sniffMouse(event);
      // if not in nav-sheet or navbar then close
      if (!this.isInNav(event)) {
        $timeout(() => this.closeMenu());
      }
    }

    isInNav(event) {
      const target = event.target;
      this.sniffMouse(event);
      if (!this.navSheet) {
        this.navSheet = $(".nav-sheet")[0];
        this.navbar = $("#main-menu")[0];
      }
      if (target === this.navSheet || target === this.navbar) {
        return true;
      }
      // far faster to use $.contains
      // 8,433,609/s
      // https://jsperf.com/child-is-in-parent
      // is that in jqLite. no !
      return (
        $.contains(this.navSheet, target) || $.contains(this.navbar, target)
      );
    }

    onMouseMove(event) {
      this.lastMouse = event;
    }

    mouseInNav() {
      // is mouse over the menu or navbar ?
      return this.lastMouse && this.isInNav(this.lastMouse);
    }

    /**
     * watch click and mouseover events to auto-close the nav-sheet
     */
    watch() {
      // this.lastMouse = null;
      this.lastActive = new Date().getTime();
      const timeout = this.isTouch ? 5000 : 239;
      // why with an interval ?
      this.interval = $interval(
        () => {
          const then = new Date().getTime() - timeout;
          // already nulled
          if (this.lastActive < then && !this.mouseInNav()) {
            this.closeMenu();
            $rootScope.$apply();
          }
        },
        timeout,
        0,
        false
      );

      body.on("click touchstart touchend keydown", (e) => this.onElsewhere(e));
      body.on("mousemove", (e) => this.onMouseMove(e));
    }

    unwatch() {
      $interval.cancel(this.interval);
      // maybe this is offing it
      body.off("click touchstart touchend keydown", (e) => this.onElsewhere(e));
      body.off("mousemove", (e) => this.onMouseMove(e));
      this.lastMouse = null;
    }
  }

  return new _Menu();
}
