import isUndefined from 'lodash/isUndefined';

searchNumFound.$inject = ["flux", "SearchService"];

/**
 * search-num-found
 *
 * Displays the number of search results found
 */
export function searchNumFound(flux, SearchService) {
  return {
    scope: {},
    replace: true,
    template: '<span class="numFound">{{ count }} found</span>',
    link: function ($scope, $element, $attr) {
      function setCount(num) {
        $scope.count = num ? num : "None";
        $element.toggleClass("hide", isUndefined(num));
      }
      function update() {
        const data = SearchService.pagination || {};
        setCount(data.displayCount || data.count);
      }

      // do not show it before you get a value
      if ($attr.searchNumFound) {
        setCount(parseInt($attr.searchNumFound, 10));
      } else {
        update();
      }

      flux.onChanged("search-results", update, $scope);
    },
  };
}
