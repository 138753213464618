import forEach from "lodash/forEach";
import extend from "lodash/extend";
import assign from "lodash/assign";
import reduce from "lodash/reduce";
import keys from "lodash/keys";
import clone from "lodash/clone";
import map from "lodash/map";
import pick from "lodash/pick";
import mapValues from "lodash/mapValues";
import isString from "lodash/isString";
import isObject from "lodash/isObject";
import isEqual from "lodash/isEqual";
import find from "lodash/find";

/**
 * SearchFilters
 * =============
 *
 * holds the current form field options
 * and the user's settings
 *
 * each different URL has different form options
 * eg. different available property-types,
 * different price ranges etc
 *
 * the form meta data is either embedded on the initial page
 * or fetched from server http://127.0.0.1:8000/Sales/manhattan/?format=form
 *
 * set and get the filters
 *
 * construct a query dict
 *
 * parse a query string
 * construct a new query string
 */
SearchFilters.$inject = ["Currency", "flux"];
export function SearchFilters(Currency, flux) {
  class _SearchFilters {
    /**
     * form data as fetched from server
     * and then extended with the globalMeta
     */
    form = {};
    /**
     * current user query
     * mutable. change to immutable, then just check identity to see if changed
     */
    query = {};

    /**
     * meta data that is common to all search pages.
     * @type {object}
     *  regions:  [[slug, title, isOnMainMenu], ...]
     *  currency: [{rate, tckr, sym}, ...]
     *  oby:      [[slug, title], ...]
     *
     */
    globalMeta = null;

    /**
     * meta data for the current search page
     * @type {object}
     *  url: /Sales/manhattan/  - without domain or query params
     *  title:
     *  srno: sales|rentals
     *  geo: {string}  - slug of region/neighborhood/country etc
     *  region: {string}  - top level region for the geo.
     *  typ: {(string|null)} - property type slug
     *  geo_children: {name, plural} - what the children of this geo are called eg. Neighborhood
     *  currency: tckr string  - default currency for region
     *  tabs: [{url, selected, title}, ...]
     *  text: intro paragraph
     */
    pageMeta = {};

    constructor() {
      flux.onAction("setFormData", (data) => {
        this.setFormData(data);
      });

      flux.onAction("setQuery", (query) => {
        this.setQuery(query);
        this.purgeQuery();
      });

      flux.onAction("setFormIsLoading", (action) => {
        this.setFormIsLoading(action);
      });

      flux.onAction("openFilter", (action) => {
        flux.changed("SearchFilters-open", action.key);
      });
    }

    /**
     * set just the form
     * setFormData sets form, page and global
     */
    setForm(f) {
      this.form = extend({}, f, this.globalMeta);
    }
    setPageMeta(m) {
      this.pageMeta = m;
    }
    setGlobalMeta(m) {
      this.globalMeta = m;
    }
    /**
     * Set the initial query as obtained from the query string via $location.search()
     * (the ?key=value GET params)
     * and converting it to the internal params format
     */
    setQuery(q) {
      this.query = assign({}, q);
      if (this.query.price) {
        // split price by _
        // parse as numbers
        const prices = this.query.price.split("_");
        this.query.min_price = parseInt(prices[0], 10) || undefined;
        this.query.max_price = parseInt(prices[1], 10) || undefined;
        delete this.query.price;
      }
      // convert the multiple checkbox options to a dict
      function objectify(val) {
        if (isString(val)) {
          val = val.split("*");
        }
        return reduce(
          val,
          function (memo, v) {
            memo[v] = true;
            return memo;
          },
          {}
        );
      }
      forEach(["periods", "geo", "types", "features"], (slug) => {
        if (this.query[slug]) {
          this.query[slug] = objectify(this.query[slug]);
        }
      });
    }
    /**
     * convert internal params back to a query dict
     * suitable for constructing url query string
     */
    getQuery() {
      // convert the multiples to name=value|value|value
      // and strip any empty values

      const params = {};
      forEach(this.query, function (v, k) {
        if (isObject(v)) {
          v = keys(v).join("*");
        }
        if (v) {
          params[k] = v;
        }
      });
      // Combine min_price and max_price as just: price
      const min_price = params.min_price ? params.min_price : "",
        max_price = params.max_price ? params.max_price : "";
      // min_price: 0, max_price: "" means any
      if (min_price || max_price) {
        params.price = "" + min_price + "_" + max_price;
      }
      delete params.min_price;
      delete params.max_price;
      return params;
    }
    // for testing
    setParams(p) {
      this.query = p;
    }
    getParams() {
      return this.query;
    }

    /**
     * user sets the value of a search query filter
     * eg. min_price, 1000000
     */
    setFilter(key, value) {
      if (this.query[key] !== value) {
        this.query[key] = value;
        this.changed(key);
      }
    }
    setFilters(obj) {
      let changed = false;
      forEach(obj, (value, key) => {
        if (this.query[key] !== value) {
          this.query[key] = value;
          changed = true;
        }
      });
      if (changed) {
        this.changed();
      }
    }
    getFilter(key) {
      return this.query[key];
    }
    /**
     * selected option description as it appears on the interface
     */
    descForFilter(key, pk) {
      const tuple = this.formOptions(key)[pk];
      return tuple ? tuple[1] : pk;
    }
    clearFilter(key) {
      delete this.query[key];
      this.changed(key);
    }

    /**
     * user sets an option on a multiple select
     * eg. 'neighborhoods', 3, true
     */
    setOption(key, option, boo) {
      if (this.prSetOption(key, option, boo)) {
        this.changed(key);
      }
    }
    prSetOption(key, option, boo) {
      const sOpt = option; // String(option);
      if (!this.query[key]) {
        this.query[key] = {};
      }
      const prev = this.query[key][sOpt];
      if (Boolean(prev) !== Boolean(boo)) {
        if (boo) {
          this.query[key][sOpt] = true;
        } else {
          delete this.query[key][sOpt];
        }
        return true;
      }
      return false;
    }
    /**
     * set all options at once
     * replacing the previous settings
     */
    setOptions(key, optionDict) {
      if (!isEqual(this.query[key] || {}, optionDict)) {
        this.query[key] = clone(optionDict);
        this.changed(key);
      }
    }
    /**
     * multiple select
     *
     * @returns {boolean} is option selected ?
     */
    optionIsSet(key, option) {
      if (this.query[key]) {
        return Boolean(this.query[key][option]);
      }
      return false;
    }
    /**
     * get selected options for a multiple select
     * for filter named 'key'
     *
     * @returns {array} pk
     */
    getOptions(key) {
      return keys(this.query[key] || {});
    }
    selectedOptionsDesc(key) {
      return map(this.query[key] || [],  (boo, pk) => {
        return this.labelForOption(key, pk);
      });
    }
    /**
     * after having set new form options, some values in the query dict
     * may no longer be valid options.
     * eg. after changing the region, neighborhood selection is invalid.
     * but prices may still be valid
     * after changing SR then prices become invalid
     *
     * @returns {bool} changed
     */
    purgeQuery() {
      const q = this.query;
      this.purgeOptions();
      this.cleanPrice();
      return !isEqual(q, this.query);
    }
    purgeOptions() {
      // Only copy params that are on the form. Don't let people or
      // extensions push crap into the app.
      // this.form comes from:
      // http://localhost:8000/Sales/manhattan/?format=form
      // the .global attribute
      const query = pick(this.query, keys(this.form));
      this.query = mapValues(query, (val, key) => {
        if (isObject(val)) {
          // val is an object {pk: true, ...}
          // form is [ [pk, desc], ...] ]
          const c = {};
          forEach(this.formOptions(key), (pkVal) => {
            const pk = pkVal[0];
            if (val[pk]) {
              c[pk] = true;
            }
          });
          return c;
        } else {
          return val;
        }
      });
    }
    // validate min_price max_price is in range for the current form
    cleanPrice() {
        // if you dont have min_price or max_price then return
        // if you don't have formOptions then return
        if (!(this.query.min_price || this.query.max_price)) {
          return;
        }
        if (!this.formOptions("max_price")) {
          return;
        }
        let minPrice = parseInt(this.query.min_price, 10) || undefined;
        let maxPrice = parseInt(this.query.max_price, 10) || undefined;
        const priceOptions = this.formOptions("max_price").slice(-2, -1)[0];
        const maxMax = parseInt(priceOptions[0], 10);

        // greater than largest option
        // less than the smallest option
        if (minPrice && minPrice > maxMax) {
          minPrice = undefined;
        }
        if (maxPrice && maxPrice > maxMax) {
          maxPrice = undefined;
        }
        if (minPrice && maxPrice && minPrice > maxPrice) {
          maxPrice = undefined;
        }
        this.query.min_price = minPrice;
        this.query.max_price = maxPrice;
    }

    labelForOption(key, option) {
      const found = find(this.formOptions(key), function (oc) {
        return String(oc[0]) === String(option);
      });
      if (found) {
        return found[1];
      }
      return undefined;
    }
    /**
     * @returns {array} options for form [[pk, label], ]
     */
    formOptions(key) {
      return this.form[key];
    }
    currencySymbol() {
      return Currency.currencySymbol();
    }
    srno() {
      return this.pageMeta.srno;
    }
    region() {
      return this.pageMeta.region;
    }
    geo() {
      return this.pageMeta.geo;
    }
    typ() {
      return this.pageMeta.typ;
    }
    changed(key) {
      flux.changed("search-query", key);
    }
    setFormIsLoading(bool) {
      if (this.formIsLoading !== bool) {
        this.formIsLoading = bool;
        flux.changed("form-is-loading", bool);
      }
    }
    url() {
      return this.pageMeta && this.pageMeta.url;
    }
    /**
     * set form, page and global from the JSON
     * response the server returns for ?format=form
     */
    setFormData(data) {
      const currentUrl = this.url();
      if (!this.globalMeta) {
        Currency.set(data.global.currency);
        const gm = clone(data.global);
        // convert from objects to tuples for form
        gm.currency = map(data.global.currency, function (el) {
          return [el.tckr, el.sym];
        });
        this.setGlobalMeta(gm);
      }

      // If changing sales <-> rentals
      // then remove the price from the query because sales and rental prices
      // are completely different.
      // But note that purgeQuery / cleanPrice also unsets
      // any non-sensical prices.
      if (data.page.srno !== this.srno()) {
        delete this.query.min_price;
        delete this.query.max_price;
      }

      this.setPageMeta(data.page);
      this.setForm(data.form);
      Currency.setDefault(data.page.currency);

      // I am changed if the URL or query changed
      const changed = this.purgeQuery() || currentUrl !== this.pageMeta.url;

      if (changed) {
        flux.changed("search-form");
      }
    }
  }

  return new _SearchFilters();
}
