insideTop.$inject = ["flux"];

/**
 * inside-top
 *
 * On some server side rendered pages there is a
 * picture or navigation at the top of the page.
 *
 * This directive removes that content (if any) when you navigate away from that to a different page.
 */

export function insideTop(flux) {
  return {
    scope: {},
    link: function ($scope, $element) {
      let removed = false;
      flux.onChanged(
        "PageStore",
        function (key) {
          if (key === "insideTop" && !removed) {
            $element.empty();
            removed = true;
          }
        },
        $scope
      );
    },
  };
}
