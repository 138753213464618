import angular from "angular";
const template = require("./navSheet.html");

navSheet.$inject = ["flux", "Menu", "device", "User", "$window"];

/**
 * nav-sheet
 *
 * The primary wide menu that appears in the top navbar,
 * displaying links for the currently selected section
 */
export function navSheet(flux, Menu, device, User, $window) {
  return {
    templateUrl: template,
    scope: {},
    replace: true,
    link: function ($scope, $element) {
      function updateSize() {
        $scope.isPhone = device.isPhone();
        $scope.sheetClass = $scope.isPhone
          ? "nav-sheet--modal"
          : "nav-sheet--widemenu";
      }

      updateSize();

      $scope.mode = null;

      $scope.menuChanged = function (state) {
        const way = Boolean(state.open);
        $scope.show = way;

        const section = (state.section || "").toLowerCase();
        // load this section and display it
        // if section is none ie. on mobile then get the main section headers
        // and handle those clicks
        // mode = properties|main|section
        if ($scope.show) {
          if ($scope.isPhone) {
            if (section === "properties") {
              $scope.mode = "properties";
              $scope.cols = Menu.getProperties();
            } else {
              // object-tools
              if (state.menu === "tools") {
                $scope.mode = "tools";
              } else {
                // menu links
                $scope.mode = "links";
                $scope.links = Menu.getLinks();
                // if we are viewing a section then link back to the menu
                $scope.backLink = state.section ? state.menu : null;
              }
              // isUser / else click to sign in / login
              // user name: click to open user menu
              if (User.isUser()) {
                $scope.username = User.name();
              }
            }
          } else {
            // desktop
            switch (section) {
              case "properties": {
                const properties = Menu.getProperties();
                $scope.mode = "properties";
                // first column is general links to commercial, new devs
                $scope.topLinks = properties[0].links;
                // subsequent columns are regions (manhattan: sales, rentals, oh, new devs)
                $scope.cols = properties.slice(1);
                break;
              }
              default:
                // one section and the links to that
                $scope.mode = "section";
                $scope.links = Menu.getPages(state.menu, state.section);
              // $scope.links = Menu.getLinks();
              // $scope.username =  null;
              // assign($scope, );
            }
          }
        }

        if ($scope.isPhone) {
          angular.element("body").toggleClass("modal-is-active", way);
          $scope.modal = way;
        }
      };

      flux.onChanged("Menu", $scope.menuChanged, $scope);

      $scope.click = function (link) {
        if (link.header) {
          flux.dispatch("openMenu", { menu: link.menu, section: link.text });
        } else {
          if (link.selected) {
            flux.dispatch("toggleMenu", { menu: link.menu });
          } else {
            if (
              link.href.match(
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
              )
            ) {
              $window.location.href = link.href;
            } else {
              flux.dispatch("gotoPath", { url: link.href });
            }
          }
        }
      };

      $scope.back = function (menu) {
        flux.dispatch("openMenu", { menu: menu });
      };

      $scope.openUserMenu = function () {
        flux.dispatch("openMenu", { menu: "user" });
      };

      $element.on("mousemove click touchstart touchend", function (event) {
        Menu.mouseIsActive(event);
      });

      flux.onChanged(
        "PageStore",
        function (key) {
          if (key === "pageSize") {
            updateSize();
          }
        },
        $scope
      );
    },
  };
}
