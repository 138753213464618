/**
 * click-card
 *
 * Used to add styling to clickable card elements
 *
 * Used on feature-properties-row and front page banners.
 */
export function clickCard() {
  return {
    scope: {},
    link: function ($scope, $element, $attrs) {
      if ($attrs.ngHref) {
        $element.addClass("clickable hover");
        // $element.on('click', function() {
        //   $element.addClass('active');
        //   $window.location = $attrs.url;
        // });
      }
    },
  };
}
