import angular from "angular";

import filter from 'lodash/filter';

const template = require("./desktopSearch.html");

desktopSearch.$inject = [
  "flux",
  "SearchFilters",
  "SearchActions",
  "PageStore",
  "$timeout",
  "$interval",
  "$window",
];

/**
 * The top area of a search page
 * including text, SRNO (sales / rentals) tabs and the search form
 *
 * This also loads search form data from the server.
 *
 * When you navigate to a different search page (change region, change sales => rentals)
 * then this loads new form data from the server and updates the form.
 */

export function desktopSearch(
  flux,
  SearchFilters,
  SearchActions,
  PageStore,
  $timeout,
  $interval,
  $window,
) {
  return {
    scope: {},
    templateUrl: template,
    link: function ($scope) {
      // videos
      const REGION_MAP = {
        manhattan: "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        // brooklyn: "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        queens: "https://media.nestseekers.com/region-videos/Queens.mp4",
        bronx: "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        "long-island":
          "https://media.nestseekers.com/region-videos/Gold Coast Long Island.mp4",
        hamptons: "https://media.nestseekers.com/region-videos/Hamptons.mp4",
        "new-jersey":
          "https://media.nestseekers.com/region-videos/New Jersey.mp4",
        // "united-kingdom": "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        "los-angeles":
          "https://media.nestseekers.com/region-videos/Los Angeles.mp4",
        italy: "https://media.nestseekers.com/region-videos/Italy.mp4",
        // spain: "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        "gold-coast-li":
          "https://media.nestseekers.com/region-videos/Gold Coast Long Island.mp4",
        florida: "https://media.nestseekers.com/region-videos/Miami.mp4",
        miami: "https://media.nestseekers.com/region-videos/Miami.mp4",
        connecticut:
          "https://media.nestseekers.com/region-videos/Connecticut.mp4",
        greenwich:
          "https://media.nestseekers.com/region-videos/Connecticut.mp4",
        colorado: "https://media.nestseekers.com/region-videos/Colorado.mp4",
        "palm-beach":
          "https://media.nestseekers.com/region-videos/Palm Beach.mp4",
        portugal: "https://media.nestseekers.com/region-videos/Portugal.mp4",
        // international: "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        "new-york": "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        // "upstate-new-york": "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        france: "https://media.nestseekers.com/region-videos/France.mp4",
        "staten-island":
          "https://media.nestseekers.com/region-videos/Manhattan.mp4",
        "caribbean-islands":
          "https://media.nestseekers.com/region-videos/Caribbean Islands.mp4",
      };

      const GEO_MAP = {
      }

      // photos
      const REGION_MAP_PHOTOS = {
        "united-kingdom":
          "https://media.nestseekers.com/region-videos/London.jpg",
        brooklyn: "https://media.nestseekers.com/region-videos/Brooklyn.jpg",
        spain: "https://media.nestseekers.com/region-videos/Spain.jpg",
        international:
          "https://media.nestseekers.com/region-videos/International.jpg",
        mexico: "https://media.nestseekers.com/region-videos/Mexico.jpg",
        greece: "https://media.nestseekers.com/region-videos/Greece.jpg",
        canada: "https://media.nestseekers.com/region-videos/Canada.jpg",
        croatia: "https://media.nestseekers.com/region-videos/Croatia.jpg",
        belgium: "https://media.nestseekers.com/region-videos/Belgium.jpg",
        'tel-aviv': "https://media.nestseekers.com/region-videos/Tel-Aviv.jpg",
        poland: "https://media.nestseekers.com/region-videos/Poland.jpeg",
        dubai: "https://media.nestseekers.com/region-videos/Dubai.jpg",
      };

      // capitalized for some reason
      const GEO_MAP_PHOTOS = {
        Turkey: "https://media.nestseekers.com/region-videos/Turkey.jpeg",
        Thailand: "https://media.nestseekers.com/region-videos/Thailand.jpeg",
        Singapore: "https://media.nestseekers.com/region-videos/Singapore.jpeg",
        Philippines: "https://media.nestseekers.com/region-videos/Philippines.jpeg",
        Malaysia: "https://media.nestseekers.com/region-videos/Malaysia.jpeg",
        Indonesia: "https://media.nestseekers.com/region-videos/Indonesia.jpeg",
        Cambodia: "https://media.nestseekers.com/region-videos/Cambodia.jpeg",
        United_Arab_Emirates: "https://media.nestseekers.com/region-videos/United Arab Emirates.jpeg",
        Croatia: "https://media.nestseekers.com/region-videos/Croatia.jpg",
        Belgium: "https://media.nestseekers.com/region-videos/Belgium.jpg",
        'Tel-Aviv': "https://media.nestseekers.com/region-videos/Tel-Aviv.jpg",
        Poland: "https://media.nestseekers.com/region-videos/Poland.jpeg",
        Dubai: "https://media.nestseekers.com/region-videos/Dubai.jpg",
        // "Toronto": "https://media.nestseekers.com/region-videos/Toronto.jpg",
      }

      let lastSearch, unwatch;
      const $ = $window.jQuery || angular.element;

      function search(debounce) {
        if (lastSearch) {
          $timeout.cancel(lastSearch);
        }
        lastSearch = $timeout(function () {
          // but query is not changed even if url is
          SearchActions.searchIfChanged();
          lastSearch = null;
        }, debounce || 1000);
      }

      function update() {
        // $scope.tabs = SearchFilters.pageMeta.tabs;
        // temp: remove New developments
        $scope.tabs = filter(SearchFilters.pageMeta.tabs, function (t) {
          return t.title !== "New Developments";
        });
        const regionBackground = SearchFilters.pageMeta.region_background;
        const geo = GEO_MAP[SearchFilters.pageMeta.geo];
        const region = REGION_MAP[SearchFilters.pageMeta.region];
        let url;
        if (geo) {
          url = geo;
        } else if (region) {
          url = region;
        } else {
          url = regionBackground;
        }
        $scope.title = SearchFilters.pageMeta.title;
        $scope.region = SearchFilters.pageMeta.region;
        // check valid url
        if (url) {
          $scope.videoHtml = `<video autoplay loop muted playsinline width="100%"><source src='${url}' type='video/mp4' /></video>`;
        } else {
          if (GEO_MAP_PHOTOS[SearchFilters.pageMeta.geo]) {
            url = GEO_MAP_PHOTOS[SearchFilters.pageMeta.geo];
          } else if (REGION_MAP_PHOTOS[SearchFilters.pageMeta.region]) {
            url = REGION_MAP_PHOTOS[SearchFilters.pageMeta.region];
          } else {
            url =
              "https://media.nestseekers.com/region-videos/International.jpg";
          }
          $scope.videoHtml = `<img src='${url}' width='100%'/>`;
        }
      }

      update();

      flux.onChanged(
        "search-form",
        function () {
          if (!PageStore.isInitialPage()) {
            update();
            search(0);
          }
        },
        $scope
      );

      flux.onChanged(
        "search-query",
        function () {
          search();
        },
        $scope
      );

      function closeAll() {
        SearchActions.openFilter();
      }

      // on opening a search filter
      // watch for body clicks that are not inside search-filters
      function watch() {
        let $searchFilters = $(".search-filters"),
          searchFilters = $searchFilters[0],
          sortBy = $(".search-results__num-found .sort-by")[0],
          lastMouseover,
          mouseInterval;

        function onClick(e) {
          // if not in searchFilters, .sort-by .secondary-regions
          if (
            !(
              $.contains(searchFilters, e.target) ||
              $.contains(sortBy, e.target)
            )
          ) {
            closeAll();
            $scope.$apply();
          }
        }
        $($window.document).on("click", onClick);

        // if user doesn't mouse over the search filters
        // within a second and isn't over the search filter at that point
        // then close them
        // run an interval to check
        function mouseMove(e) {
          lastMouseover = e;
        }
        $searchFilters.on("mouseenter mouseleave mousemove", mouseMove);

        mouseInterval = $interval(
          function () {
            if (lastMouseover) {
              const now = new Date().getTime(),
                old = now - lastMouseover.timeStamp;
              // it is outside of element if last event was a mouseout|mouseleave[ie]
              if (old > 2719 && lastMouseover.type === "mouseout") {
                closeAll();
              }
            }
          },
          413,
          0,
          false
        );

        unwatch = function () {
          $($window.document).off("click", onClick);
          $searchFilters.off("mouseenter mouseleave mousemove", mouseMove);
          $interval.cancel(mouseInterval);
          unwatch = undefined;
        };
      }

      flux.onChanged(
        "SearchFilters-open",
        function (key) {
          if (key) {
            // cancel any impending auto-search on opening a new filter
            // should probably also cancel a server request
            if (lastSearch) {
              $timeout.cancel(lastSearch);
              lastSearch = null;
            }
            if (!unwatch) {
              watch();
            }
            // undefined key means its closing
          } else if (unwatch) {
            unwatch();
          }
        },
        $scope
      );

      $scope.$on("$destroy", function () {
        if (unwatch) {
          unwatch();
        }
        if (lastSearch) {
          $timeout.cancel(lastSearch);
        }
      });
    },
  };
}
