import angular from "angular";

export function modal() {
  /**
   * Open or close a modal dialog
   *
   * Requires bootstrap-modal.js
   *
   * @param {string} elementId sizzle selector of element with modal content
   * @param {Boolean} show
   */
  return function (elementId, show) {
    const el = angular.element(elementId);
    const body = angular.element("body");

    // Toggle the body class which inhibits
    // scrolling while the modal is open.
    // Otherwise you see the scroll bars.
    if (!el.hasBeenModalized) {
      el.hasBeenModalized = true;
      el.on("hide", function () {
        body.toggleClass("modal-is-active", false);
      });
      el.on("show", function () {
        body.toggleClass("modal-is-active", true);
      });
    }

    if (el.modal) {
      el.modal(show ? "show" : "hide");
    }
  };
}
