trkForm.$inject = ["Analytics"];
clientPageView.$inject = ["Analytics", "User"];
clientEmitTrk.$inject = ["Analytics", "User"];
emitTrk.$inject = ["Analytics"];
trk.$inject = ["Analytics"];

/**
 * Sends tracking when you click on the element
 * <a trk="some-event-name">...</a>
 */

export function trk(Analytics) {
  return {
    link: function ($scope, $element, $attr) {
      $element.on("click", function () {
        Analytics.track($attr.trk);
      });
    },
  };
}

/**
 * Immediately sends a tracking event.
 * <div emit-trk="some-conversion-happened"></div>
 * This is for putting a conversion event on a page.
 * Easy to add or remove the tags on the page
 * without changing the frontend app.
 */

export function emitTrk(Analytics) {
  return {
    link: function ($scope, $element, $attr) {
      Analytics.track($attr.emitTrk);
    },
  };
}

/**
 * Client only
 * Analytics already does not send events for staff
 * so its only important to know that its a user
 *
 * <div client-emit-trk="some-conversion-happened"></div>
 */

export function clientEmitTrk(Analytics, User) {
  return {
    link: function ($scope, $element, $attr) {
      if (User.isUser()) {
        Analytics.track($attr.clientEmitTrk);
      }
    },
  };
}

/**
 * Client page view
 *
 * Analytics already does not send events for staff
 * so its only important to know that its a user
 *
 * category:name
 * <div client-page-view="search:rentals"></div>
 *
 * name
 * <div client-page-view="new-developments"></div>
 *
 * defaults to title or something that segment.io chooses
 */

export function clientPageView(Analytics, User) {
  return {
    link: function ($scope, $element, $attr) {
      const names = $attr.clientPageView.split(":");
      if (User.isUser()) {
        Analytics.page(names[0], names[1]);
      }
    },
  };
}

/**
 * Track a form submit
 *
 * segment.io adds a delay in order to allow the tracking to be sent
 * before the browser relocates
 * <form trk-form="some-conversion-happened" id="form-name"></form>
 */

export function trkForm(Analytics) {
  return {
    link: function ($scope, $element, $attr) {
      Analytics.trackForm($element.attr("id"), $attr.trkForm);
    },
  };
}
