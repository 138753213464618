import clone from "lodash/clone";
import forEach from "lodash/forEach";

Currency.$inject = ["$filter", "flux"];
export function Currency($filter, flux) {
  class _Currency {
    /**
     * {Array} of objects {rate, tckr, sym}
     */
    currencies = [];
    selected = null;
    dfault = { tckr: "USD", sym: "$", rate: 1.0 };

    constructor() {
      flux.onAction("setQuery", (query) => {
        if (query.currency) {
          this.select(query.currency);
        }
      });
    }

    /**
     * set all currencies
     *
     * @param {Array} cs - of objects {rate, tckr, sym}
     */
    set(cs) {
      this.currencies = clone(cs);
    }
    /**
     * @param {string} ticker
     */
    getCurrency(ticker) {
      let found;
      forEach(this.currencies, (c) => {
        if (ticker === c.tckr) {
          found = c;
        }
      });
      return found;
    }
    /**
     * each region may specify a dfault currency
     * eg. london is GBP
     * if user has not selected a currency explictly
     * then this dfault is used.
     */
    setDefault(ticker) {
      if (this.dfault && this.dfault.tckr === ticker) {
        return;
      }
      this.dfault = this.getCurrency(ticker);
      flux.changed("Currency");
    }
    /**
     * user selects a currency to search/display results
     * setting to null will remove user preference
     * and the dfault will prevail
     */
    select(ticker) {
      if (this.selected && this.selected.tckr === ticker) {
        return;
      }
      this.selected = ticker ? this.getCurrency(ticker) : null;
      flux.changed("Currency");
    }
    currencySymbol() {
      return (this.selected || this.dfault).sym;
    }
    /**
     * convert dollar value to another currency
     *
     * @param {float} value   - dollar value
     * @param {String} ticker - eg. EUR GBP
     * @returns {String} formatted money string
     */
    convert(value, ticker) {
      const c = this.getCurrency(ticker),
        val = value / c.rate;
      return $filter("currency")(val, c.sym, 0);
    }
  }

  return new _Currency();
}
