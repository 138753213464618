import angular from "angular";

const tpl = require("./picGallery.html");

picGallery.$inject = ["$window", "$timeout", "imgTools", "device", "modal"];

/**
  * pic-gallery
  *
  *  apt and bldg picture gallery
  *
  *
    <pic-gallery pics="{{ json }}" aspect-ratio="{{ scheme.aspect_ratio }}">fallback content</div>
  *
  * optional:  initial [index of initially selected image]
  *
  * image-size="full|large"  default large
  *     full is for fullscreen and uses the highest res pictures with
  *       possibly different aspect ratios
  *     large is normal gallery mode : images are all the same aspect ratio
  *
  * pics="json string" A JSON string containing:
  *
  * scheme
  *   width
  *   height
  *   aspect_ratio
  * tiny
  *   width
  *   height
  *   aspect_ratio
  * aptpics
  *  .imgurl .title .width .height [.full, .tiny ]
  * bldgpics
  *  .imgurl .title .width .height [.full, .tiny ]
  * floorplans
  *  .imgurl .title .width .height [.full, .tiny ]
  * pdfs
  *  .url .title
  * videos
  *  .url .imgurl .icon
  */
export function picGallery($window, $timeout, imgTools, device, modal) {
  return {
    scope: {
      pics: "@",
    },
    templateUrl: tpl,
    replace: true,
    link: function ($scope, $element, $attr) {
      let $ = $window.$ || angular.element,
        blank =
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==",
        pics = [],
        // rgGallery is where the gallery itself is.
        // it is a sibling of modalContent
        // which is used for fullscreen mode
        // and will be filled with another picGallery
        // with another rgGallery
        // that is also inside this $element.
        rgGallery = $(".rg-gallery", $element).eq(0),
        galleryNav = $(".gallery-nav", rgGallery),
        navHeight = 37, // default based on css
        navOffset = 0,
        rgImage = $(".rg-image", rgGallery),
        aspectRatio = $attr.aspectRatio,
        imageSize = $attr.imageSize || "large",
        initial = parseInt($attr.initial || 0, 10) || 0,
        containerWidth,
        containerHeight,
        parent,
        document = $window.document,
        // this pic-gallery is inside a .modalContent of another one
        // therefore we are in fullscreen mode
        isFullScreen = rgGallery.parents(".modalContent").length;

      $scope.isFullScreen = isFullScreen;
      $scope.ready = false;

      $scope.$watch("pics", function (picsJson) {
        if (picsJson) {
          init(JSON.parse(picsJson));
        }
      });

      function init(data) {
        $scope.data = data;
        angular.forEach(data, function (v, k) {
          $scope[k] = v;
        });

        // number them sequentially
        let i = 0;
        angular.forEach(
          ["aptpics", "bldgpics", "floorplans", "pdfs", "videos"],
          function (key) {
            angular.forEach($scope[key] || [], function (obj) {
              obj.index = i;
              obj.typeKey = key;
              obj.context = {};
              i += 1;
              // avoid any large images in tiny
              if (obj.tiny === obj.full) {
                obj.tiny = blank;
              }
              pics.push(obj);
            });
          }
        );

        // initialize
        $scope.canFullscreen = !isFullScreen && pics.length;
        calcSizes();

        if (isFullScreen) {
          // needs to re-center the images if in full screen mode
          // non-fs uses pure css
          $($window).on("resize", onResize);
        }
        $(document).on("keydown", onKeypress);

        $element.bind("$destroy", function () {
          if (isFullScreen) {
            $($window).off("resize", onResize);
          }
          $(document).off("keydown", onKeypress);
        });

        // after compile
        if (pics.length) {
          $scope.ready = true;
          $timeout(function () {
            selectByNumber(parseInt(initial, 10) || 0);
          }, 1);

          if ($scope.isFullScreen) {
            $scope.preloadAll();
          }
        } else {
          // there are no pictures
          parent.hide();
        }
      }

      // parent div, used to resize the inner elements
      if ($attr.parent) {
        // I don't think $attr.parent is used now
        parent = $element.parents($attr.parent);
      } else {
        parent = $element.parent();
      }
      // $scope.currentSrc = blank;

      function selectNavItem(i) {
        const lis = galleryNav.find("div");
        lis.removeClass("selected");
        lis.eq(i).addClass("selected");
      }

      function selectItem(obj) {
        selectNavItem(obj.index);
        $scope.current = obj;
      }

      function selectByNumber(index) {
        const pic = pics[index];
        if (!pic) {
          return;
        }
        switch (pic.typeKey) {
          case "videos":
            $scope.selectVid(pic);
            break;
          case "aptpics":
          case "bldgpics":
          case "floorplans":
            $scope.selectPic(pic);
            break;
          case "pdfs":
            $scope.selectPdf(pic);
        }
      }

      $scope.loading = false;
      $scope.selectPic = function (pic) {
        const url = pic[imageSize] || pic.imgurl;
        if (pic === $scope.current) {
          return;
        }
        selectItem(pic);
        if (!pic.context.loaded) {
          $scope.loading = true;
          $scope.current = pic;
          imgTools.loadImg(
            url,
            pic.context,
            function () {
              $scope.$apply(function () {
                $scope.loading = false;
                fillMissingThumb(pic);
                displayPic(pic);
              });
            },
            function () {
              $scope.$apply(function () {
                $scope.loading = false;
                // display error image
              });
            }
          );
        } else {
          fillMissingThumb(pic);
          displayPic(pic);
        }
      };

      function fillMissingThumb(pic) {
        // if tiny was a full size image
        // then it was set to blank.
        // having now loaded the full size
        // we can show the thumb.
        if (pic.tiny === blank) {
          pic.tiny = pic.imgurl;
        }
      }

      function preloadNext(i, cb, andFillThumb) {
        let pic = pics[i],
          url;
        if (pic) {
          if (pic.context.loaded) {
            if (andFillThumb) {
              fillMissingThumb(pic);
            }
            if (cb) {
              cb(i);
            }
          } else {
            url = pic[imageSize] || pic.imgurl;
            imgTools.loadImg(url, pic.context, function () {
              if (andFillThumb) {
                $scope.$apply(function () {
                  fillMissingThumb(pic);
                });
              }
              if (cb) {
                cb(i);
              }
            });
          }
        }
      }

      $scope.preloadAll = function () {
        if ($scope.preloaded) {
          return;
        }
        // if mobile then don't
        function callNext(i) {
          if (pics[i + 1]) {
            preloadNext(i + 1, callNext, true);
          } else {
            $scope.preloaded = true;
          }
        }
        preloadNext(0, callNext, true);
      };

      $scope.selectPdf = function (pdf) {
        selectItem(pdf);
        // or just open it in a new window ?
        const $pdf = $(`<a class="pdf" target=_new></a>`);
        $pdf.attr("href", pdf.url);
        // pdf.prepend($('<img/>').attr('src', $thumb.attr('src')));
        $pdf.append(pdf.title ? pdf.title : " View PDF");
        displayEl($pdf, pdf.context);
      };

      $scope.selectVid = function (vid) {
        if ($scope.current && $scope.current.index === vid.index) {
          return;
        }
        selectItem(vid);
        const $vid = $(
          `<div class="autovideo"><iframe src="${vid.url}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>`
        );
        if (!vid.context) {
          vid.context = {};
        }
        displayEl($vid, vid.context);
      };

      function displayPic(pic) {
        // image URL should already be preloaded
        // and context set
        const $large = $("<img/>").hide();
        const url = pic[imageSize] || pic.imgurl;
        $large.attr("src", url);
        displayEl($large, pic.context);
        $scope.current = pic;
        preloadNext(pic.index + 1);
      }

      function resizeEl($el, context) {
        // only needed for fullscreen when the images
        // are not sized to the correct shape
        imgTools.resizeImg(
          $el,
          rgImage,
          context,
          aspectRatio,
          containerWidth,
          containerHeight,
          imageSize === "full"
        );
      }

      function displayEl($el, context) {
        if (imageSize === "full") {
          resizeEl($el, context);
        }
        // store it here in case of resizing.
        // not a good design
        $el.data("context", context);

        rgImage.empty().append($el);
        $el.fadeIn(50);
      }

      // init
      function calcSizes() {
        // height is 0 on initial load because there is no content
        if (imageSize === "full") {
          navHeight = Math.max(
            (galleryNav.outerHeight() || 0) - navOffset,
            navHeight
          );
          const screenSize = device.screenSize();
          // IE safe
          containerWidth = $window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          containerHeight = $window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

          containerHeight = containerHeight - navHeight;
          rgImage.css({ width: containerWidth, height: containerHeight });
          if (navOffset !== containerHeight) {
            navOffset = containerHeight;
            $element.find(".gallery-nav").css("top", navOffset);
          }
        }
      }
      // navigate(dir)

      $scope.fullscreen = function () {
        if (isFullScreen || !pics.length) {
          // throw Inception
          return;
        }

        // launch modal with gallery
        // which embeds another pic-gallery into a hidden div
        // and then calls bootstrap modal on it
        const initialIndex = $scope.current.index,
          dataJSON = $attr.pics;

        // inject new angular content into the modal-body
        // using the dynamic directive
        $scope.modalContent = `<div class="modal fade hide" id="modalGallery" tabindex="-1" role="dialog" aria-hidden="true"><div class="modal-body"><div class="gallery-frame select-none"><div pic-gallery aspect-ratio="${aspectRatio}" image-size="full" initial="${initialIndex}" pics='${dataJSON}'></div><a data-dismiss="modal" class="closer"><i class="fa fa-times-circle"></i></a></div></div></div>`;

        $timeout(function () {
          // after modal content is added
          modal($element.find(".modal"), true);
        }, 1);
      };

      $scope.next = function () {
        let nextIndex = $scope.current.index + 1;
        if (nextIndex >= pics.length) {
          nextIndex = 0;
        }
        selectByNumber(nextIndex);
        $scope.preloadAll();
      };

      $scope.previous = function () {
        let nextIndex = $scope.current.index - 1;
        if (nextIndex < 0) {
          nextIndex = pics.length - 1;
        }
        selectByNumber(nextIndex);
        $scope.preloadAll();
      };

      function onResize() {
        // only if full
        calcSizes();
        const $el = rgImage.children().eq(0);
        if ($el.length) {
          resizeEl($el, $el.data("context"));
        }
      }

      function onKeypress(e) {
        if (isFullScreen || e.originalEvent.target === document.body) {
          if (e.keyCode === 37) {
            $scope.previous();
          }
          if (e.keyCode === 39) {
            $scope.next();
          }
        }
      }
    },
  };
}
