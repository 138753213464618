embedIframe.$inject = ["$sce"];

/**
 * embed-iframe
 */
export function embedIframe($sce) {
  return {
    scope: {
      url: "=",
    },
    // ng-if="url"
    template:
      '<div class="autovideo">' +
      '<iframe ng-src="{{embedUrl}}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' +
      "</div>",
    replace: true,
    link: function ($scope) {
      $scope.$watch("url", function () {
        if ($scope.url) {
          $scope.embedUrl = $sce.trustAsResourceUrl($scope.url);
        } else {
          $scope.embedUrl = "";
        }
      });
    },
  };
}
