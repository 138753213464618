searchForm.$inject = ["device", "flux"];

/**
 * search-form
 *
 * This directive displays either modal-search or desktop-search depending on the screen size (phone or tablet/desktop).
 */
export function searchForm(device, flux) {
  return {
    scope: {},
    template:
      '<div ng-switch on="isPhone"><div modal-search ng-switch-when="true" class="nsan-toggle-fade"></div><div desktop-search ng-switch-when="false" class="nsan-toggle-fade"></div></div>',
    link: function ($scope) {
      $scope.isPhone = device.isPhone();

      flux.onChanged(
        "PageStore",
        function (key) {
          if (key === "pageSize") {
            $scope.isPhone = device.isPhone();
          }
        },
        $scope
      );
    },
  };
}
