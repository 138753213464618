searchResults.$inject = [
  "SearchService",
  "PageStore",
  "$compile",
  "lazyLoad",
  "flux",
  "$timeout",
];

/**
 * search-results
 *
 * Displays ResultsPage results of properties listings.
 * Dynamically updates the results table with new search results.
 *
 * Updates on these flux events:
 *
 *  "search-results"
 *  "search-is-searching"
 *
 * The rows are html which contain angular directives, so it has to compile them and insert them on the page.
 * Then it activates lazyLoad so that the images are loaded.
 */
export function searchResults(
  SearchService,
  PageStore,
  $compile,
  lazyLoad,
  flux,
  $timeout
) {
  return {
    scope: {},
    template:
      '<tbody class="search-results__body" ng-class="{\'search-results__body_loading\': loading}"><tr class="search-results__loadingplaceholder" ng-show="loadingPlaceholder"><td colspan="4" class="search-results__placeholder pt4">searching...</td></tr></tbody>',
    replace: true,
    link: function ($scope, $element) {
      let childScope;

      lazyLoad.start();

      function updateIsSearching(searching) {
        // display as loading
        $scope.loading = searching;
        // show placeholder iff there is nothing in the server side content
        $scope.loadingPlaceholder =
          PageStore.serverSideContentRemoved && searching;
      }

      updateIsSearching(SearchService.searching);

      function update() {
        let html, e;
        const data = SearchService.results;
        if (!data) {
          return;
        }

        if (childScope) {
          // destroy previous scope before pushing new content
          childScope.$destroy();
        }
        childScope = $scope.$new();
        if (data.count) {
          html = data.rows.join("");
          e = $compile(html)(childScope);
          lazyLoad.setSrcInElement(e, 10);
          $element.empty().prepend(e);
        } else {
          // TODO use a none found directive that shows help
          html =
            '<tr><td colspan="4"><div class="inform">None found</div></td></tr>';
          e = $compile(html)(childScope);
          $element.empty().prepend(e);
        }
      }
      update();
      flux.onChanged(
        "search-results",
        function () {
          $timeout(update, 1);
        },
        $scope
      );

      flux.onChanged("search-is-searching", updateIsSearching, $scope);
    },
  };
}
