/**
 * helper directive to dynamically insert html with angular elements in it
 *
 * like ng-bind-html but it can bind html that has angular directives in it
 * and they will be compiled
 *
 * usage:
 * <div dynamic="modalContent"></div>
 *
 * Then set $scope.modalContent to the angular template content you wish to display
 *  http://stackoverflow.com/questions/18157305/angularjs-compiling-dynamic-html-strings-from-database
 */
dynamic.$inject = ["$compile"];

export function dynamic($compile) {
  return {
    link: function ($scope, $element, $attrs) {
      // should create child scope
      // and destroy that and replace with content
      let childScope;
      $scope.$watch($attrs.dynamic, function (html) {
        if (childScope) {
          childScope.$destroy();
        }
        childScope = $scope.$new();
        $element.html(html);
        $compile($element.contents())(childScope);
      });
    },
  };
}
