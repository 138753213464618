/**
 * User
 * ====
 *
 * Get User data if the User is logged in.
 * Retreives from UserStorage
 *
 * Uses $window.$.get
 */
User.$inject = ["UserStorage", "$cookies", "$q", "$window"];
export function User(UserStorage, $cookies, $q, $window) {
  let data,
    deferred,
    userId = 0;
  const api = {
    get: function (cb) {
      if (api.isUser()) {
        if (data) {
          cb(data);
        } else {
          // need to get it
          api.fetch().then(cb);
        }
      }
    },
    isUser: function () {
      return Boolean(api.userId());
    },
    userId: function () {
      if (userId === 0) {
        userId = $cookies.get("uid") || undefined;
      }
      return userId;
    },
    menu: function (cb) {
      // its the same data
      return api.get(cb);
    },
    data: function () {
      return data;
    },
    name: function () {
      return data && data.name;
    },
    // is_staff
    // phone
    // email
    fetch: function () {
      if (!deferred) {
        deferred = $q.defer();
        UserStorage.fetch("menu", api.prFetch, function (response) {
          data = response;
          deferred.resolve(data);
        });
      }
      return deferred.promise;
    },
    prFetch: function (cb) {
      // jQuery
      $window.$.get("/MyNest/menu", cb);
    },
  };

  return api;
}
