import angular from "angular";
import forEach from "lodash/forEach";

const tpl = require("./modalSearch.html");

modalSearch.$inject = [
  "SearchActions",
  "SearchQuery",
  "modal",
  "flux",
  "SearchFilters",
  "priceUtils",
  "$location",
  "$timeout",
];

/**
  * The top area of the search page for small screens
  *
  * This is the mobile counterpart to desktop-search
  *
  * It shows a minimal display of your current search: title and searchDescription
  *
  * On click it opens the full search interface as a modal dialog.

  * Including text, SRNO tabs and the search form
  * the search filters are all children of this
  */
export function modalSearch(
  SearchActions,
  SearchQuery,
  modal,
  flux,
  SearchFilters,
  priceUtils,
  $location,
  $timeout
) {
  return {
    scope: true,
    templateUrl: tpl,
    link: function ($scope, $element) {
      const $ = angular.element;
      $scope.modalIsOpen = false;

      const GEO_MAP_MOBILE_PHOTOS = {
        Turkey: "https://media.nestseekers.com/region-videos/Turkey-mobile.jpeg",
        Thailand: "https://media.nestseekers.com/region-videos/Thailand-mobile.jpeg",
        Singapore: "https://media.nestseekers.com/region-videos/Singapore-mobile.jpeg",
        Philippines: "https://media.nestseekers.com/region-videos/Philippines-mobile.jpeg",
        Malaysia: "https://media.nestseekers.com/region-videos/Malaysia-mobile.jpeg",
        Indonesia: "https://media.nestseekers.com/region-videos/Indonesia-mobile.jpeg",
        Cambodia: "https://media.nestseekers.com/region-videos/Cambodia-mobile.jpeg",
        Croatia: "https://media.nestseekers.com/region-videos/Croatia.jpg",
        Belgium: "https://media.nestseekers.com/region-videos/Belgium.jpg",
        United_Arab_Emirates: "https://media.nestseekers.com/region-videos/United Arab Emirates-mobile.jpeg",
        'Tel-Aviv': "https://media.nestseekers.com/region-videos/Tel-Aviv-mobile.jpg",
        Poland: "https://media.nestseekers.com/region-videos/Poland-mobile.jpeg",
        Dubai: "https://media.nestseekers.com/region-videos/Dubai-mobile.jpg",
      }

      const REGION_MAP_MOBILE_PHOTOS = {
        brooklyn:
          "https://media.nestseekers.com/region-videos/Brooklyn-mobile.jpg",
        "caribbean-islands":
          "https://media.nestseekers.com/region-videos/Caribbean Islands-mobile.jpg",
        colorado:
          "https://media.nestseekers.com/region-videos/Colorado-mobile.jpg",
        connecticut:
          "https://media.nestseekers.com/region-videos/Connecticut-mobile.jpg",
        france: "https://media.nestseekers.com/region-videos/France-mobile.jpg",
        "gold-coast-li":
          "https://media.nestseekers.com/region-videos/Hamptons-mobile.jpg",
        greenwich:
          "https://media.nestseekers.com/region-videos/Greenwich-mobile.jpg",
        hamptons:
          "https://media.nestseekers.com/region-videos/Hamptons-mobile.jpg",
        international:
          "https://media.nestseekers.com/region-videos/International-mobile.jpg",
        italy: "https://media.nestseekers.com/region-videos/Italy-mobile.jpg",
        "los-angeles":
          "https://media.nestseekers.com/region-videos/Los Angeles-mobile.jpg",
        manhattan:
          "https://media.nestseekers.com/region-videos/International-mobile.jpg",
        mexico: "https://media.nestseekers.com/region-videos/Mexico-mobile.jpg",
        croatia: "https://media.nestseekers.com/region-videos/Croatia-mobile.jpg",
        belgium: "https://media.nestseekers.com/region-videos/Belgium-mobile.jpg",
        miami: "https://media.nestseekers.com/region-videos/Miami-mobile.jpg",
        florida: "https://media.nestseekers.com/region-videos/Miami-mobile.jpg",
        "new-jersey":
          "https://media.nestseekers.com/region-videos/New Jersey-mobile.jpg",
        "palm-beach":
          "https://media.nestseekers.com/region-videos/Palm Beach-mobile.jpg",
        "united-kingdom":
          "https://media.nestseekers.com/region-videos/London-mobile.jpg",
        portugal:
          "https://media.nestseekers.com/region-videos/Portugal-mobile.jpg",
        spain: "https://media.nestseekers.com/region-videos/Spain-mobile.jpg",
        queens: "https://media.nestseekers.com/region-videos/Queens-mobile.jpg",
        greece: "https://media.nestseekers.com/region-videos/Greece-mobile.jpg",
        canada: "https://media.nestseekers.com/region-videos/Canada-mobile.jpg",
        'tel-aviv': "https://media.nestseekers.com/region-videos/Tel-Aviv-mobile.jpg",
        poland: "https://media.nestseekers.com/region-videos/Poland-mobile.jpeg",
        dubai: "https://media.nestseekers.com/region-videos/Dubai-mobile.jpg",
      };

      function searchDescription() {
        const parts = [],
          q = SearchFilters.query;
        if (q.min_price || q.max_price) {
          const desc = priceUtils.description(
            q.min_price,
            q.max_price,
            SearchFilters.currencySymbol()
          );
          parts.push(desc);
        }
        // bedrooms
        if (q.bedrooms) {
          let _label = SearchFilters.descForFilter("bedrooms", q.bedrooms);
          if (_label !== "Studio") {
            _label += "BR";
          }
          parts.push(_label);
        }
        // bathrooms
        if (q.bathrooms) {
          const _label2 = SearchFilters.descForFilter("bathrooms", q.bathrooms);
          parts.push(_label2);
        }
        forEach(
          ["periods", "neighborhoods", "types", "features"],
          function (key) {
            forEach(SearchFilters.selectedOptionsDesc(key), function (word) {
              parts.push(word);
            });
          }
        );
        if (parts.length) {
          return parts.join(" ");
        }
        return "Search by location, price, type, features >>";
      }

      function update() {
        $scope.srno = SearchFilters.pageMeta.srno;
        // the server provides a title for SEO
        // but we want a compact and succint one here
        $scope.title =
          (SearchFilters.pageMeta.geo ? SearchFilters.pageMeta.geo.replace('_', ' ').replace('-', ' ') : SearchFilters.pageMeta.region ? SearchFilters.pageMeta.region : "") + " " + SearchFilters.pageMeta.srno;
        $scope.searchDescription = searchDescription();
        $scope.region = SearchFilters.pageMeta.region;
        let url;
        if (GEO_MAP_MOBILE_PHOTOS[SearchFilters.pageMeta.geo]) {
          url = GEO_MAP_MOBILE_PHOTOS[SearchFilters.pageMeta.geo];
        } else if (REGION_MAP_MOBILE_PHOTOS[SearchFilters.pageMeta.region]) {
          url = REGION_MAP_MOBILE_PHOTOS[SearchFilters.pageMeta.region];
        } else {
          url =
            "https://media.nestseekers.com/region-videos/International-mobile.jpg";
        }
        $scope.background = {
          "background-image": `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${url}')`,
        };
      }
      update();

      flux.onChanged(
        "search-form",
        function () {
          update(); // update description and title

          // search if its a new URL
          // but only iff modal is not open
          // (if modal is open then wait for user to click search)
          if (!$scope.modalIsOpen) {
            SearchActions.searchIfChanged();
          }
        },
        $scope
      );

      flux.onChanged(
        "search-query",
        function () {
          $scope.searchDescription = searchDescription();
        },
        $scope
      );

      // expose this controller api to children
      // for them to declare when they open
      // so that others may shut.
      // TODO: move to flux
      $scope.openFilter = function (key) {
        $scope.$broadcast("filter-did-open", key);
      };

      $scope.toggleModal = function (way) {
        $scope.modalIsOpen = way;
        modal("#modal-search", way);
        // store state somewhere
        flux.dispatch("set-page-store", {
          modalSearchIsOpen: way,
        });
        if (!way) {
          // closing: OK search
          // change URL if needed,
          // just doing a replace
          const url = SearchQuery.buildUrl();
          $location.path(url).replace();
          // but do not let the controller fetch a new form
          $scope.openFilter(); // shut any that were open
          SearchActions.searchIfChanged();
        } else {
          // after open set max-height of .modal-search__filters
          // so that if the search form content exceeds the height
          // it will auto-scroll.
          $timeout(
            function () {
              const max =
                $(".modal-search__modal").height() -
                $(".modal-search__srno").height() -
                $(".modal-search__ok").height() -
                8;
              $element.find(".modal-search__filters").css("max-height", max);
            },
            1000,
            false
          );
        }
      };

      $scope.changeSr = function (sr) {
        if (sr !== $scope.srno) {
          $scope.srno = sr;
          SearchActions.changeSr(sr);
        }
      };
    },
  };
}
