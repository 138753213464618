/* eslint no-unused-vars: 0 */

// In previous webpack this was included on page and then declared as external for webpack
import jquery from "jquery";
import angular from "angular";
import ngRoute from "angular-route";
import ngTouch from "angular-touch";
import ngAnimate from "angular-animate";
import cookies from "angular-cookies";
import cache from "angular-cache";
import resource from "angular-resource";
import checklist from "checklist-model";
import submodule from "./submoduler";

import * as controllers from "./controllers/public";
import * as services from "./services/public";
import * as filters from "./filters";
import * as directives from "./directives/public";


// The name this module will be registered as
const servicesPublic = "services.public";

/**
 * Collects and registers all modules used in the public application.
 *
 * This is used by both the public app and also included in the admin angular app.
 */
const publicModule = angular.module("public", [
  // By angular convention this modules should all export their name,
  // but not all of them do.
  // The modules need to be used here so they don't get tree-shaken away.

  "ngRoute" || ngRoute,
  "ngTouch" || ngTouch,
  "ngAnimate" || ngAnimate,
  "checklist-model" || checklist,
  "ngCookies" || cookies,
  submodule(servicesPublic, ["ngCookies" || cookies, "angular-cache" || cache, "ngResource" || resource], { factory: services }),
  submodule("controllers.public", [servicesPublic, "ngRoute"], {
    controller: controllers
  }),
  submodule("directives.public", [servicesPublic], { directive: directives }),
  submodule("filters.public", [], { filter: filters })
]);

// tests do not have a normal webpage
// so these should not run in testing
if (process.env.NODE_ENV !== "testing") {
  publicModule.run([
    "inits",
    function(inits) {
      inits.ngRouteOnlyForSearchPageCtrl();
    }
  ]);
}
export default publicModule;
