/**
 * public.js
 *
 * This is the Nest Seekers angular frontend app for the public site
 *
 * It is activated when <html ... ng-app="public"> is set and public.js is included
 * on the page.
 */
import appConfig from "./appConfig";
import publicModule from "./publicModule";

export default appConfig(publicModule).name;
