scrollTo.$inject = ["$anchorScroll"];

/**
 * scroll-to
 *
 * On click, scroll to a #hash anchor on the page
 *
 * <a scroll-to="scroll-to-this-href">click to scroll</a>
 */
export function scrollTo($anchorScroll) {
  return {
    link: function ($scope, $element, $attrs) {
      $element.on("click", function () {
        $anchorScroll($attrs.scrollTo);
      });
    },
  };
}
