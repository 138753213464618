toggleMenu.$inject = ["flux", "Analytics", "Menu"];

/**
 * toggle-menu
 * a button for phones to toggle the navigation overlay
 */
export function toggleMenu(flux, Analytics, Menu) {
  return {
    link: function ($scope, $element) {
      $element.on("click", function (event) {
        $scope.$apply(function () {
          Menu.mouseIsActive(event);
          flux.dispatch("toggleMenu", {
            menu: "main",
          });
          Analytics.track("mobileToggleMenu");
          event.preventDefault();
        });
      });

      flux.onChanged(
        "Menu",
        function (state) {
          // menu or user
          const open = state.open && state.menu !== "tools";
          $element.attr("aria-pressed", open ? "true" : "false");
          $element.toggleClass("upside-down", open || false);
        },
        $scope
      );
    },
  };
}
