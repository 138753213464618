import angular from "angular";

/**
 * click-listing
 *
 * Adds a click handler to the tbody
 * to enable clicking anywhere on any row to navigate to the view listing page.
 *
 * adds .active to the parent tr on click (to show that the navigation is in progress)
 */

clickListing.$inject = ["$location"];

export function clickListing($location) {
  return {
    link: function ($scope, $element) {
      function onClick(event) {
        const target = event.originalEvent && event.originalEvent.target;
        if (event.metaKey || event.ctrlKey || event.shiftKey || event.altKey) {
          return;
        }
        if (target) {
          if (target.tagName.match(/A|I|BUTTON/)) {
            return;
          }
          const $target = angular.element(target);
          // if its a click inside the .saved-listing then let it propagate
          const savedListing = $target.parents(".saved-listing");
          if (!savedListing.length) {
            const tr = $target.parents("tr");
            const href = tr.find("a.hline").attr("href");
            if (href) {
              // need local path
              const relative = href.match(/:\/\/[^/]+(\/.+)$/);
              if (relative) {
                // add .active to the row
                tr.addClass("active");
                $scope.$apply(function () {
                  $location.path(relative[1]);
                  $location.search({});
                });
                event.preventDefault();
              }
            }
          }
        }
      }
      $element.on("click", onClick);
    },
  };
}
