import forEach from "lodash/forEach";
import extend from "lodash/extend";

/**
 * A flux store for page layout and state changes.
 * Things like page title, inner title, show userMenu
 * URL history as you navigate.
 *
 * flux.dispatch("set-page-store", {
 *   pageTitle: SearchFilters.pageMeta.pageTitle,
 *   innerTitle: ""
 * });
 *
 */
PageStore.$inject = ["flux",  "$rootScope", "$location"];
export function PageStore(flux,  $rootScope, $location) {
  class _PageStore {
    vals = {};
    history = [];
    serverSideContentRemoved = false;
    url = null;

    constructor() {
      flux.onAction("set-page-store", (action) => {
        this.vals = extend(this.vals, action);
        forEach(action, function (v, key) {
          flux.changed("PageStore", key);
        });
      });

      flux.onAction("remove-server-side-content", () => {
        if (!this.serverSideContentRemoved) {
          this.serverSideContentRemoved = true;
          flux.changed("server-side-content");
        }
      });

      $rootScope.$on("$locationChangeSuccess", () => {
        const url = $location.path();
        this.history.push(url);
        this.url = url;
        flux.changed("url", url);
      });
    }

    set(key, val) {
      this.vals[key] = val;
    }
    get(key) {
      return this.vals[key];
    }

    isInitialPage() {
      return this.history.length <= 1;
    }
  }

  return new _PageStore();
}
