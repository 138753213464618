import angular from "angular";
import modalLoginTpl from "./modalLoginTpl.html";

ModalLogin.$inject = [
  "$location",
  "device",
  "modal",
  "$timeout",
  "$templateCache",
];

export function ModalLogin($location, device, modal, $timeout, $templateCache) {
  /**
   * Returns a function `ModalLogin.open` that opens the modal login/signup dialog.
   *
   * If the visitor is on mobile then it just relocates to login page.
   *
   * Uses the 'modal' service which is a wrapper for bootstrap-modal.js
   */
  const modalLogin = "#modalLogin";
  const $ = angular.element;

  return {
    open: function () {
      let submitting = false;

      // on mobile just use the normal page, not modal
      if (device.isPhone()) {
        return $location.path("/MyNest/sign_up");
      }

      if (!$(modalLogin).length) {
        $("footer").before($templateCache.get(modalLoginTpl));
      }

      modal(modalLogin, true);
      /**
       * To prevent double submitting forms
       * and getting a duplicate client error on signup,
       * deactivate the form after its been submitted
       * just like noDoubleSubmit does for most forms.
       * This form is being loaded by bootstrap modal
       * so it isn't compiled in angular-space.
       *
       * bootstrap has not yet loaded the remote form html
       * so give it some time
       */
      $timeout(function () {
        $(modalLogin + " form").on("submit", function (e) {
          if (!submitting) {
            $(e.target).addClass("ng-submitted");
            submitting = true;
          } else {
            e.preventDefault();
          }
        });
      }, 2000);
    },
  };
}
