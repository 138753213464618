import map from 'lodash/map';

import has from 'lodash/has';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';

const tpl = require("./modalSearchFilter.html");
modalSearchFilter.$inject = ["SearchFilters",  "flux"];

/**
 * A filter row in the modal search form
 * eg. 'region', 'bedrooms' etc.
 *
 * <modal-search-filter title="Region" [multiple] key="region" class="u"></modal-search-filter>
 *
 * Displays the title.
 * When title is clicked then open the options display
 * when an option is clicked then set it
 *
 * multiple: can select multiple options
 *
 * region has a different form type and a different action (relocates, fetches new form)
 *
 * param title
 * param key
 * param suffix - eg. add '+' to each bedroom option label
 * param {Boolean} multiple selects multiple items
 */
export function modalSearchFilter(SearchFilters,  flux) {
  return {
    scope: true,
    templateUrl: tpl,
    replace: true,
    link: function ($scope, $element, $attrs) {
      const key = $attrs.key;
      const isMultiple = has($attrs, "multiple");
      const suffix = $attrs.suffix || "";
      const typesPhrase = $attrs.title;

      function isSelected(pk) {
        if (isMultiple) {
          return SearchFilters.optionIsSet(key, pk);
        } else {
          return SearchFilters.getFilter(key) === pk;
        }
      }

      function title() {
        if ($scope.isSet) {
          if (isMultiple) {
            const vals = keys(SearchFilters.getOptions(key)),
              numSelected = vals.length;
            // list one type or "X Types"
            if (numSelected === 0) {
              return $attrs.title;
            }
            // show up to 2
            if (numSelected <= 2) {
              return SearchFilters.selectedOptionsDesc(key).join(", ");
            }
            return "" + numSelected + " " + typesPhrase;
          } else {
            const val = SearchFilters.getFilter(key);
            return val
              ? SearchFilters.labelForOption(key, val) + suffix
              : typesPhrase;
          }
        } else {
          return $attrs.title;
        }
      }

      function update() {
        if (isMultiple) {
          $scope.isSet = !isEmpty(SearchFilters.getOptions(key));
        } else {
          // technically correct:
          // $scope.isSet = !isUndefined(SearchFilters.getFilter(key));
          // but bedrooms = 0 is considered not set
          $scope.isSet = SearchFilters.getFilter(key);
        }
        $scope.title = title();
        $scope.options = map(SearchFilters.formOptions(key), function (vvv) {
          return {
            pk: vvv[0],
            desc: vvv[1] + suffix,
            selected: isSelected(vvv[0]),
          };
        });
      }

      $scope.opened = false;
      $scope.open = function () {
        $scope.opened = !$scope.opened;
        if ($scope.opened) {
          $scope.openFilter(key);
        }
      };

      $scope.select = function (pk) {
        if (isMultiple) {
          SearchFilters.setOption(key, pk, !isSelected(pk));
        } else {
          SearchFilters.setFilter(key, pk);
        }
      };

      flux.onChanged(
        "search-query",
        function (what) {
          if ((what || key) === key) {
            update();
          }
        },
        $scope
      );
      flux.onChanged("search-form", update, $scope);

      // if anybody else opens, I close
      $scope.$on("filter-did-open", function (e, openingKey) {
        if (key !== openingKey) {
          $scope.opened = false;
        }
      });

      update();
    },
  };
}
