// this template is shared by directives in public
import map from "lodash/map";

const tpl = require("./popupSelect.html");

/**
 * Service popupSelect
 *
 * Utility for making all the popup / select widgets for the Search forms.
 *
 * A factory to make a directive to filter for X or greater.
 *
 * eg. if you have options:
 *
 *   Studio+
 *   1+
 *   2+
 *
 * Used in desktopSearchFilters
 *
 * The template is also used by currencySwitch since it is the same html
 */
popupSelect.$inject = [
  "SearchFilters",
  "SearchActions",
  "priceUtils",
  "flux",
];
export function popupSelect(SearchFilters, SearchActions,  priceUtils, flux) {
  /**
   * a factory to make a directive
   * to filter for X or greater than
   * Studio+
   * 1+
   * 2+
   *
   * @param {String} key The slug/key for the checkbox field group
   * @param {String} anyPhrase The text shown when nothing is selected: All The Things
   *
   * @returns {Object} directive definition
   */
  return function (key, anyPhrase, suffix) {
    return {
      scope: {},
      templateUrl: tpl,
      link: function ($scope) {
        $scope.opened = false;

        function updateDescription() {
          if ($scope.val) {
            $scope.description =
              SearchFilters.labelForOption(key, $scope.val) + suffix;
          } else {
            $scope.description = anyPhrase;
          }
        }

        function updateOptions() {
          $scope.options = map(SearchFilters.formOptions(key), (opt) =>
            priceUtils.makeOption(opt, $scope.val, suffix)
          );
        }

        $scope.toggle = function () {
          if ($scope.opened) {
            commit();
            return close();
          }

          updateOptions();
          SearchActions.openFilter(key);
        };

        function close() {
          SearchActions.openFilter();
        }

        function commit() {
          SearchFilters.setFilter(key, $scope.val);
        }

        function setOpened(bool) {
          if ($scope.opened !== bool) {
            updateDescription();
            $scope.opened = bool;
          }
        }

        $scope.select = function (val) {
          $scope.val = val;
          close();
        };

        function update() {
          updateOptions();
          // do not show the widget at all if there are no options
          // for this region or srno type
          $scope.showFilter = $scope.options && $scope.options.length;
          if ($scope.showFilter) {
            $scope.val = SearchFilters.getFilter(key);
            updateDescription();
          }
        }

        flux.onChanged(
          "search-query",
          function (e, what) {
            if ((what || key) === key) {
              update();
            }
          },
          $scope
        );
        flux.onChanged("search-form", update, $scope);

        flux.onChanged(
          "SearchFilters-open",
          function (openedKey) {
            // somebody else opened
            const itsMe = openedKey === key;

            if ($scope.opened && !itsMe) {
              // I am open and somebody else is now opening
              // so commit changes
              commit();
            }

            setOpened(itsMe);
          },
          $scope
        );

        update();
      },
    };
  };
}
