const tpl = require("./searchPageFooter.html");

searchPageFooter.$inject = ["SearchFilters", "flux"];

/**
 * search-page-footer
 *
 * Shows related links and content at bottom of search page
 * changing when you navigate between different Sales / Rentals / regions
 */
export function searchPageFooter(SearchFilters, flux) {
  return {
    scope: {},
    templateUrl: tpl,
    replace: true,
    link: function ($scope) {
      $scope.one = "";
      $scope.two = "";
      $scope.three = "";
      flux.onChanged(
        "search-form",
        function () {
          const [a, b, c] = SearchFilters.pageMeta.footers;
          $scope.one = a;
          $scope.two = b;
          $scope.three = c;
        },
        $scope
      );
    },
  };
}
