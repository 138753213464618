import forEach from "lodash/forEach";
const tpl = require("./nsPagination.html");

/**
 * from angular-ui-bootstrap
 * changed config defaults
 * changed template markup to match previous result_set styling
 * changed name so it does not conflict if ui-bootstrap is also loaded
 */

const paginationConfig = {
  itemsPerPage: 10,
  boundaryLinks: false,
  directionLinks: true,
  firstText: "First",
  previousText: "‹‹ previous",
  nextText: "next ››",
  lastText: "Last",
  rotate: false,
};

nsPagination.$inject = ["device"];

export function nsPagination(device) {
  return {
    scope: {
      state: "=",
      onSelectPage: "&",
    },
    templateUrl: tpl,
    replace: true,
    link: function ($scope, $element) {
      const narrow = device.isPhone(),
        maxPageLinks = narrow ? 3 : 5;

      function calculateTotalPages(count, limit) {
        const totalPages = limit < 1 ? 1 : Math.ceil(count / limit);
        return Math.max(totalPages || 0, 1);
      }

      function update() {
        const totalPages = calculateTotalPages(
          $scope.state.count,
          $scope.state.limit
        );
        $scope.pages = makePages(
          $scope.state.page,
          totalPages,
          paginationConfig
        );
        $scope.show = totalPages > 1;
      }

      const w1 = $scope.$watch("state", update);

      // $scope.$on('$destroy', function() {
      //   // this is not getting destroyed when serverSideContent is removed !
      //   console.log('destroy scope nsPagination', $scope.id);
      // });
      $element.on("$destroy", function () {
        // When its in serverSideContent and the parent element is removed
        // the child scope is not destroyed
        // but $destroy is sent to the elements
        w1();
        // kill self just in case
        $scope.$destroy();
      });

      $scope.selectPage = function ($event, page) {
        $event.preventDefault();
        if (!(page.disabled || page.active)) {
          // best to show it as highlighted in place
          $scope.state.pending = page.text;
          update(); // will wiggle into the middle now
          $scope.onSelectPage({ page: page.number });
        }
      };

      // Create page object used in template
      function makePage(i, number, text, lastPage, isPrev, isNext, ellipsis) {
        const classes = ["page"];
        forEach(
          {
            ellipsis: ellipsis === true,
            prev: isPrev === true,
            next: isNext === true,
            current: !$scope.state.pending && number === $scope.state.page,
            leaving: $scope.state.pending && number === $scope.state.page,
            // actually depends on what button was pressed
            pending: text === $scope.state.pending,
            disabled: number <= 0 || number > lastPage,
          },
          function (val, key) {
            if (val) {
              classes.push(key);
            }
          }
        );

        return {
          number: number,
          i: i,
          text: text,
          classes: classes.join(" "),
        };
      }

      function makePages(currentPage, totalPages, config) {
        // pages:  active disabled prev next number text

        const lastPage = totalPages;
        const pages = [];

        const numRotates = Math.min(maxPageLinks, totalPages);
        const lastRotate = Math.min(
          Math.ceil(currentPage + numRotates / 2.0),
          lastPage + 1
        );
        const firstRotate = Math.max(lastRotate - numRotates, 1);

        // prev
        pages.push(
          makePage(
            pages.length,
            currentPage - 1,
            config.previousText,
            lastPage,
            true,
            false
          )
        );

        // ...
        // if first rotate is > 1
        if (!narrow && firstRotate > 1) {
          pages.push(
            makePage(
              pages.length,
              firstRotate - 1,
              ".",
              lastPage,
              false,
              false,
              true
            )
          );
        }

        // neighbor
        // current
        // neighbor
        let lastRotateLoop = lastRotate;
        if (lastPage <= numRotates) {
          lastRotateLoop = lastPage + 1;
        }

        for (let i = firstRotate; i < lastRotateLoop; i++) {
          pages.push(makePage(pages.length, i, i, lastPage));
        }

        // ...
        // if last rotate is < lastPage
        if (!narrow && lastRotate < lastPage) {
          pages.push(
            makePage(
              pages.length,
              lastRotate + 1,
              "...",
              lastPage,
              false,
              false,
              true
            )
          );
        }

        // next
        pages.push(
          makePage(
            pages.length,
            currentPage + 1,
            config.nextText,
            lastPage,
            false,
            true
          )
        );

        return pages;
      }
    },
  };
}
