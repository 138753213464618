import angular from "angular";
import forEach from "lodash/forEach";

/**
 * Create a submodule and register controller, directive, filter from
 * simple implementation functions.
 *
 * This allows the implementations to be written without reference to
 * the angular.module that it will be registered with.
 *
 * It makes them much more bare and readable. They are just functions and don't
 * have all the Angular boilerplate you usually see.
 *
 * Implementations are loaded at compile/build time (and can therefore throw
 * import errors then) and registered at runtime.
 */
export default function submodule(name, dependencies, implementations) {
  const mod = angular.module(name, dependencies);

  try {
    forEach(implementations, (fns, registerType) => {
      forEach(fns, (imp, key) => {
        if (!name.match(/^_[a-z]/)) {
          // ignore private methods. exported for unit tests
          if (imp.config) {
            mod.config(imp.config);
          }
          mod[registerType].call(null, key, imp);
        }
      });
    });
  } catch (e) {
    throw new Error(
      "Failed to register submodule: " +
        name +
        JSON.stringify(implementations, null, 2) +
        e
    );
  }

  return name;
}
