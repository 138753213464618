import map from 'lodash/map';

const tpl = require("./modalSearchFilter.html");

modalRegionFilter.$inject = ["SearchActions", "SearchFilters",  "flux"];

/**
 * region is not a query parameter submitted with GET
 * it results in the base URL changing
 *
 * This is used in the mobile/modal search dialog
 * it changes the region without changing the URL.
 * When the dialog is closed the URL is changed
 * and the search is done.
 */

export function modalRegionFilter(SearchActions, SearchFilters,  flux) {
  return {
    // region openFilter
    scope: true,
    replace: true,
    templateUrl: tpl,
    link: function ($scope) {
      const key = "regions";

      function update(region) {
        // use locally set region if avail, don't wait for the SearchFilters to fetch and update
        region = region || SearchFilters.pageMeta.region;
        $scope.title = SearchFilters.labelForOption(key, region);
        $scope.options = map(SearchFilters.formOptions(key), function (vvv) {
          return {
            pk: vvv[0],
            desc: vvv[1],
            selected: region === vvv[0],
          };
        });
      }
      $scope.isSet = true;

      $scope.opened = false;
      $scope.open = function () {
        $scope.opened = !$scope.opened;
        if ($scope.opened) {
          $scope.openFilter(key);
        }
      };

      $scope.select = function (slug) {
        SearchActions.changeRegion(slug);
        update(slug);
      };
      $scope.$on("filter-did-open", function (e, openingKey) {
        if (key !== openingKey) {
          $scope.opened = false;
        }
      });
      flux.onChanged(
        "search-query",
        function (e, what) {
          if ((what || key) === key) {
            update();
          }
        },
        $scope
      );
      flux.onChanged("search-form", update, $scope);

      update();
    },
  };
}
