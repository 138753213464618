import map from 'lodash/map';
import template from "../../services/public/popupSelect.html";

currencySwitch.$inject = [
  "Currency",
  "SearchFilters",
  "SearchActions",

  "priceUtils",
  "flux",
];

/**
 * currency-switch
 *
 * Used to change currencies for search on the desktop-search (on properties search pages)
 */
export function currencySwitch(
  Currency,
  SearchFilters,
  SearchActions,

  priceUtils,
  flux
) {
  const key = "currency";

  return {
    scope: {},
    templateUrl: template,
    link: function ($scope) {
      $scope.opened = false;

      function updateDescription() {
        $scope.description = Currency.currencySymbol();
      }

      $scope.options = map(SearchFilters.formOptions(key), (opt) =>
        priceUtils.makeOption(opt, $scope.val, "")
      );

      $scope.toggle = function () {
        if ($scope.opened) {
          commit();
          SearchActions.openFilter();
          return;
        }
        SearchActions.openFilter(key);
      };

      function setOpened(bool) {
        if (bool !== $scope.opened) {
          $scope.opened = bool;
          updateDescription();
        }
      }

      function commit() {
        // if ticker is same as region default
        // then unset user preference and remove from query params
        const ticker = $scope.val === Currency.dfault.tckr ? null : $scope.val;
        Currency.select(ticker);
        SearchFilters.setFilter(key, ticker);
      }

      // shows control as highlighted if val is set
      $scope.select = function (val, $event) {
        $event.stopPropagation();
        $scope.val = val;
        SearchActions.openFilter(); // close on select
      };

      function update() {
        // do not show the widget at all if there are no options
        // for this region or srno type
        $scope.showFilter = $scope.options && $scope.options.length;
        if ($scope.showFilter) {
          $scope.val = (Currency.selected || Currency.dfault).tckr;
          updateDescription();
        }
      }

      flux.onChanged(
        "SearchFilters-open",
        function (openedKey) {
          // somebody else opened
          const itsMe = openedKey === key;

          if ($scope.opened && !itsMe) {
            // I am open and somebody else is now opening
            // so commit changes
            commit();
          }

          setOpened(itsMe);
        },
        $scope
      );

      flux.onChanged("Currency", update, $scope);

      update();
    },
  };
}
