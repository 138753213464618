import angular from "angular";

/**
 * SavedListings
 *
 * saves SavedListing to the server
 * and to local cache
 *
 * saves the IDs of saved listings in persistant UserStorage
 * so that lookup is fast to know whether to fetch the note or not
 *
 * prefetches the most recent 50 saved listings
 *
 * and fetches others on demand from server
 *
 * acts as a flux store: sends flux.changed('SavedListings', aptId)
 * upon loading from server
 */
SavedListings.$inject = ["$http", "$q", "UserStorage", "flux"];
export function SavedListings($http, $q, UserStorage, flux) {
  let resource_uri = "/api/private/v1/savedlisting/?limit=50",
    ids_uri = "/api/private/v1/savedlisting/ids/",
    cache = UserStorage,
    q,
    api = {},
    SAVED_LISTINGS = "SavedListings",
    savedIds = {};

  function key(aptId) {
    // for the in memory savedIds dict
    return "" + aptId;
  }

  function ckey(aptId) {
    // for localStorage cache
    return "sl." + aptId;
  }

  /**
   * get SavedListing, returning immediately if
   * or request from server and return undefined for now
   */
  api.get = function (aptId, orFetch) {
    let got, lq;
    lq = q || api.init();
    got = cache.get(ckey(aptId));
    if (got) {
      return got;
    }
    // fetch it after preloading is complete
    // and if its not fetched already
    if (orFetch) {
      lq.then(function () {
        if (savedIds[key(aptId)] && !cache.get(ckey(aptId))) {
          api.prFetchApt(aptId);
        }
      });
    }
  };

  /**
   * fetch SavedListing from server
   */
  api.prFetchApt = function (aptId) {
    $http
      .get("/api/private/v1/savedlisting/?apt_id=" + aptId)
      .then(function (response) {
        const data = response.data;
        if (data.meta.total_count) {
          const sl = data.objects[0];
          api.put(aptId, sl);
          flux.changed("SavedListing", key(aptId));
        }
      });
  };

  /**
   * save to cache
   */
  api.put = function (aptId, savedListing) {
    cache.put(ckey(aptId), savedListing);
    // only save if its not already set
    savedIds[key(aptId)] = true;
    api.saveIds();
  };

  /**
   * post SavedListing to server and save to local cache.
   * does not send a changed event
   *
   * @returns {Promise} which resolves when the server confirms
   */
  api.saveApt = function (aptId, obj) {
    obj.apt_id = aptId;
    obj.apt = "/api/private/v1/apt/" + aptId + "/";

    api.put(aptId, obj);

    // update
    if (obj.resource_uri) {
      return $http.put(obj.resource_uri, obj);
    }
    // create
    return $http.post(resource_uri, obj).then(function (response) {
      // HTTP 201
      // Location: http://127.0.0.1:8000/api/private/v1/savedlisting/16193/
      const a = document.createElement("a");
      a.href = response.headers().location;
      obj.resource_uri = a.pathname;
      return obj;
    });
  };

  api.saveIds = function () {
    UserStorage.put(SAVED_LISTINGS, Object.keys(savedIds));
  };

  /**
   * fetches the user's saved listings apt_ids
   * and preloads the first 50
   * resolves q
   */
  api.fetch = function () {
    q = $q.all([api.fetchIds(), api.fetchListings()]);
    return q;
  };

  /**
   * fetch a list of all user's SavedListings
   */
  api.fetchIds = function () {
    // fetches saved listings apt_ids
    return $http.get(ids_uri).then(function (response) {
      savedIds = {};
      angular.forEach(response.data.ids, function (aptId) {
        savedIds[key(aptId)] = true;
      });
      api.saveIds();
    });
  };

  /**
   * fetch the first 50 saved listings
   * which for most people is all of them.
   */
  api.fetchListings = function () {
    // should only do this if there are some to get !
    // otherwise just resolve the q
    // also pre load the first 50
    // or make a view that returns just those
    return $http.get(resource_uri).then(function (response) {
      const re = /(\d+)\/$/;
      angular.forEach(response.data.objects, function (sl) {
        const apt_id = re.exec(sl.apt)[1];
        sl.apt_id = apt_id;
        api.put(apt_id, sl);
        flux.changed("SavedListing", key(apt_id));
      });
    });
  };

  /**
   * preload ids and first 50 saved listings
   * returns the promise, q
   */
  api.init = function () {
    // because UserStorage is used
    // the entire localStorage will be wiped
    // if session/user/version changes
    const idsList = UserStorage.get(SAVED_LISTINGS);
    if (!idsList) {
      // fetch both
      return api.fetch();
    }
    // array -> dict
    savedIds = {};
    angular.forEach(idsList, function (id) {
      savedIds[key(id)] = true;
    });

    // fetch first 50 listings
    q = api.fetchListings();
    return q;
  };

  return api;
}
