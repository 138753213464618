import angular from "angular";

/**
 * SearchFormParams
 *
 * Fetches the form parameters and meta data
 * for a search page from the django backend by making a GET request
 * to the normal search page URL with additional param: ?format=form
 *
 * eg.
 * https://www.nestseekers.com/Sales/los-angeles/?format=form
 * https://www.nestseekers.com/Sales/westwood/?format=form
 * https://www.nestseekers.com/sales/manhattan/vacant-lot?format=form
 *
 * Responds to flux actions:
 *  "fetchForm"
 * Dispatches flux events:
 *  setFormIsLoading
 *  setFormData
 */
SearchFormParams.$inject = [
  "$q",
  "$window",
  "$http",
  "$log",
  "flux",
  "$location",
  "$timeout",
];
export function SearchFormParams(
  $q,
  $window,
  $http,
  $log,
  flux,
  $location,
  $timeout
) {
  class _SearchFormParams {
    cache = {};
    lastFormUrl = null;

    constructor() {
      flux.onAction("fetchForm", (url) => {
        this.fetchForm(url);
      });
    }

    /**
     * Fetch form data.
     *
     * @returns {Promise}
     */
    fetch(url) {
      const lower = url.toLowerCase();
      return $http
        .get(__BACKEND__ + "" + url, {
          params: {
            format: "form",
          },
          cache: true, // this is double cached, but I see double loads being logged
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then(
          (response) => {
            if (response.data) {
              this.cache[lower] = response.data;
              return response.data;
            }

            const error = {
              status: response.status,
              contentType: response.headers("content-type"),
              url,
            };
            $log.error(error);
            return $q.reject({ error });
          },
          function (response) {
            // Returning or rejecting here does not work ?
            // What kind of Promise is this ?
            if (response.data) {
              $log.error("SearchFormParams fetch:", response.data);
              const goto = response.data.error && response.data.error.goto;
              if (goto) {
                $location.path(goto);
              } else {
                $window.location.reload();
              }
              return $q.reject(response.data);
            } else {
              $log.error(response);
              return $q.reject({ error: response.status });
            }
          }
        );
    }

    /**
     * Serve from cache if available, else fetch.
     * May return data or a promise
     */
    fetchp(url) {
      const data = this.cache[url.toLowerCase()];
      if (data) {
        return $q.resolve(data);
      }
      return this.fetch(url);
    }

    /**
     * fetches the form data if needed
     * and dispatches that data via flux
     */
    fetchForm(url) {
      const lower = url.toLowerCase();
      this.lastFormUrl = url;
      if (this.cache[lower]) {
        flux.dispatch("setFormData", this.cache[lower]);
        flux.dispatch("setFormIsLoading", false);
      } else {
        flux.dispatch("setFormIsLoading", true);
        this.fetch(url).then(
          () => {
            // user may have switched to a new region or srno since the request was initiated
            if (url === this.lastFormUrl) {
              $timeout(() => {
                if (this.cache[lower]) {
                  // if 404/410 when fetching form then nothing is saved to cache.
                  flux.dispatch("setFormData", this.cache[lower]);
                  flux.dispatch("setFormIsLoading", false);
                }
              }, 1);
            }
          },
          function () {
            flux.dispatch("setFormIsLoading", false);
          }
        );
      }
    }

    addToCache(url, data) {
      this.cache[url.toLowerCase()] = data;
    }

    bootstrap() {
      // it its a search page then load search form data from json
      const payload = angular.element("#search-form-payload");
      if (payload.length !== 0) {
        this.addToCache($location.path(), payload.data("search-form-payload"));
      }
    }
  }

  return new _SearchFormParams();
}
