import filter from "lodash/filter";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";

/**
 * priceUtils service
 *
 * Utility functions for parsing and formatting prices in human friendly ways.
 */
priceUtils.$inject = ["$filter", "SearchFilters"];
export function priceUtils($filter, SearchFilters) {
  const mults = {
    k: 1000,
    m: 1000000,
    b: 1000000000,
  };
  const kmb = /^[^0-9]*([0-9., ]+)([kmbKMB])$/,
    nonNumChars = /[^0-9.]/;

  const api = {
    /**
     * expand any trailing k/m/b
     * to the full number
     *
     * ignore any starting characters
     *
     * if no number is matched, return undefined
     */
    expandKMB: function (val) {
      const match = val.match(kmb);
      let cleanNum;

      if (!match) {
        return undefined;
      }
      cleanNum = match[1];
      cleanNum = cleanNum.replace(nonNumChars, "");
      cleanNum = parseFloat(cleanNum);
      cleanNum *= mults[match[2].toLowerCase()];
      return cleanNum;
    },
    /**
     * clean the numberical input
     * converting it to a number
     *
     * @param {string} val
     * @returns {number}
     */
    cleanNum: function (val) {
      let clean, num, match;
      clean = api.expandKMB(val);
      if (!isUndefined(clean)) {
        return clean;
      }
      // parse inner number
      num = /^[^0-9]*([0-9.,]+)[ ]*$/;
      match = val.match(num);
      if (match) {
        clean = match[1];
        clean = clean.replace(nonNumChars, "");
        return parseInt(clean, 10);
      }
      return undefined;
    },
    /**
     * format money value with currency
     * prefix with currency symbol
     * abbreviating with k or mm
     * removing any decimal points
     *
     * @param {number} val
     * @param {string} symbol - currency symbol
     * @returns {string}
     */
    currency: function (val, symbol) {
      let c, m;
      if (!val) {
        return "";
      }
      if (isUndefined(symbol)) {
        symbol = "";
      }
      c = $filter("currency")(val, symbol, 0);
      // remove pennies
      c = c.replace(/\.[0-9]{2}$/, "");
      // abbrev with k M B if possible
      m = c.replace(/,000,000,000$/, "B");
      if (m !== c) {
        return m;
      }
      m = c.replace(/,000,000$/, "M");
      if (m !== c) {
        return m;
      }
      // only if there are less than 3 digits before it
      m = c.match(/^.?[0-9]{1,3},000$/);
      if (m) {
        return c.replace(/,000$/, "k");
      }
      return c;
    },
    /**
     * format description $x - $y
     */
    description: function (minVal, maxVal, currency, anyPhrase) {
      if (!(minVal || maxVal)) {
        return anyPhrase;
      }
      if (minVal) {
        if (maxVal) {
          if (minVal === maxVal) {
            return api.currency(minVal, currency);
          }
          return (
            api.currency(minVal, currency) +
            " - " +
            api.currency(maxVal, currency)
          );
        }
        return api.currency(minVal, currency) + "+";
      }
      return "Up to " + api.currency(maxVal, currency);
    },
    makeOption: function (tuple, selected, suffix) {
      return {
        pk: tuple[0],
        desc: tuple[1] + (suffix || ""),
        selected: tuple[0] === selected,
      };
    },
    minPriceOptions: function (minVal) {
      // undefined is default which is ''
      if (isUndefined(minVal)) {
        minVal = "";
      }
      // slice off 'no limit'
      return map(
        SearchFilters.formOptions("min_price").slice(0, -1),
        (tuple) => api.makeOption(tuple, minVal, "")
      );
    },
    maxPriceOptions: function (maxVal, filterHigherThan) {
      let minVal = filterHigherThan || 0;
      // undefined is default which is ''
      if (isUndefined(maxVal)) {
        minVal = "";
      }
      return map(
        filter(SearchFilters.formOptions("max_price"), function (opt) {
          if (opt[0] === "") {
            // no limit
            return true;
          }
          return opt[0] >= minVal;
        }),
        (opt) => api.makeOption(opt, maxVal, "")
      );
    },
  };
  return api;
}
