import { format } from "fecha";

tel.$inject = ["$sce"];
mailto.$inject = ["$sce"];
unsafe.$inject = ["$sce"];
trusted.$inject = ["$sce"];

/**
 * unsafe
 *
 * replicates the old ng-bind-html-unsafe="variable"
 *
 * <div ng-bind-html="variable|unsafe"></div>
 *
 * {{ variable|unsafe }}
 *
 */
export function unsafe($sce) {
  return function (val) {
    return $sce.trustAsHtml(val);
  };
}

/**
 * yesno
 *
 * Displays "Yes" or "No"
 */
export function yesno() {
  return function (val) {
    return val ? "Yes" : "No";
  };
}

/**
 * mailto
 *
 * <a href="mailto:val"
 */
export function mailto($sce) {
  return function (val) {
    if (val) {
      return $sce.trustAsHtml(`<a href="mailto:${val}">${val}</a>`);
    }
    return "";
  };
}

/**
 * tel
 *
 * <a href="callto:{val}"
 */
export function tel($sce) {
  return function (val) {
    if (val) {
      return $sce.trustAsHtml(`<a href="callto:${val}">${val}</a>`);
    }
    return "";
  };
}

/**
 * Date time formatting with a format string
 *
 * {{ oh.scheduled_till|amDateFormat:'h:mm a' }}
 *
 * https://www.npmjs.com/package/fecha
 */
export function amDateFormat() {
  /**
   * @param value:number - Unix timestamp
   * @param fmt:string - fecha datetime format string
   */
  return function (value, fmt) {
    const time = new Date(value * 1000);
    return format(time, fmt);
  };
}

export function trusted($sce) {
  return function () {
    return $sce.trustAsHtml;
  };
}
