const dualFilterTpl = require("./dualFilter.html");
const priceFilterTpl = require("./dualPriceFilter.html");

sortBy.$inject = ["popupSelect"];
rentalPeriods.$inject = ["checkboxFilter"];
geoFilter.$inject = ["checkboxFilter", "SearchFilters"];
featuresFilter.$inject = ["checkboxFilter"];
typesFilter.$inject = ["checkboxFilter"];
areaFilter.$inject = ["dualFilter"];
sqftFilter.$inject = ["popupSelect"];
priceFilter.$inject = ["dualPriceFilter"];
bathroomsFilter.$inject = ["popupSelect"];
bedroomsFilter.$inject = ["popupSelect"];

/**
 * Displays the bedrooms filter as text.
 *
 * When clicked, opens a popup select to select a value
 * on selecting that it closes.
 */

export function bedroomsFilter(popupSelect) {
  return popupSelect("bedrooms", "Bedrooms", "+");
}

/**
 * Displays the bathrooms filter as text.
 *
 * When clicked, opens a popup select to select a value
 * on selecting that it closes.
 */

export function bathroomsFilter(popupSelect) {
  return popupSelect("bathrooms", "Bathrooms", "+");
}

/**
 * Displays the price filter as text.
 *
 * When clicked, opens a popup select to select min value
 * on selecting a min value, it opens the max value
 * on selecting that it closes.
 */

export function priceFilter(dualPriceFilter) {
  return {
    scope: {},
    templateUrl: priceFilterTpl,
    link: dualPriceFilter("price", "Price"),
  };
}

/**
 * Sq Ft for commercial properties
 */

export function sqftFilter(popupSelect) {
  return popupSelect("sq_ft", "Sqft", "+");
}

/**
 * Filter by property area (SQ FT)
 */

export function areaFilter(dualFilter) {
  return {
    scope: {},
    templateUrl: dualFilterTpl,
    // TODO: where is dualFilter ? it is like dualPriceFilter but no currency
    link: dualFilter("sq_ft", "Area"),
  };
}

/**
 * Filter search by the type: brownstone, walk-up, mansion etc.
 */

export function typesFilter(checkboxFilter) {
  return checkboxFilter("types", "Property type", "Types");
}

/**
 * Filter search by features: furnished, outdoor-space, etc.
 */

export function featuresFilter(checkboxFilter) {
  return checkboxFilter("features", "Features", "Features");
}

/**
 * geo-filter
 *
 * Filter search by neighborhood / town
 */

export function geoFilter(checkboxFilter, SearchFilters) {
  function getMeta() {
    return (
      SearchFilters.pageMeta.geo_children || {
        name: "Neighborhood",
        plural: "Neighborhoods",
      }
    );
  }

  function anyPhrase() {
    // if there are no geo_children then its empty
    const name = getMeta().name;
    return name || "";
  }

  function typesPhrase() {
    return getMeta().plural;
  }

  return checkboxFilter("geo", anyPhrase, typesPhrase);
}

/**
 * rental-periods
 *
 * Filter search by rental period
 */

export function rentalPeriods(checkboxFilter) {
  return checkboxFilter("periods", "Rental periods", "rental periods");
}

/**
 * thesort-by
 *
 * a small pop up to change the sort by
 */

export function sortBy(popupSelect) {
  return popupSelect("oby", "Featured", "");
}
