const tpl = require("./suggestedListings.html");

suggestedListings.$inject = ["ajaxviews", "Analytics"];

/**
 * Show Suggested Listings
 *
 * Always has something to show for a client or anonymous visitor or agent
 *
 **/
export function suggestedListings(ajaxviews, Analytics) {
  return {
    templateUrl: tpl,
    scope: {},
    link: function ($scope, $element, $attr) {
      ajaxviews.get(
        "suggested_listings",
        {
          num: $attr.num,
        },
        $scope
      );

      $scope.goto = function (apt) {
        // You clicked on a link, so it will go there naturally.
        // This sends the tracking.
        Analytics.track("clicked-suggested-listing", {
          apt: apt.id,
        });
      };
    },
  };
}
