import angular from "angular";

const tpl = require("./searchPage.html");

SearchPageCtrl.config = function ($routeProvider) {
  $routeProvider.when("/srno/:subpath*/", {
    controller: "SearchPageCtrl",
    templateUrl: tpl,
    // Each parameter in resolve is a function that
    // returns a Promise and needs to be resolved
    // before the controller renders.
    resolve: {
      formData: [
        "SearchFormParams",
        "$location",
        function (SearchFormParams, $location) {
          return SearchFormParams.fetchp($location.path());
        },
      ],
    },
    track: "search",
    reloadOnSearch: false,
    caseInsensitiveMatch: true,
  });

  const $route = $routeProvider.$get[$routeProvider.$get.length - 1]({
    $on: function () {},
  });

  // overwrite the regex for the route
  const re =
    /^\/(Sales|Rentals|ForSale|ForRent|commercial|commercial-lease)\/(.*)$/i;
  // angular has two entries but they aren't needed with this custom re
  $route.routes["/srno/:subpath*/"].regexp = re;
  $route.routes["/srno/:subpath*"].regexp = re;
};

SearchPageCtrl.config.$inject = ["$routeProvider"];

SearchPageCtrl.$inject = [
  "$scope",
  "formData",
  "$location",
  "SearchActions",
  "SearchQuery",
  "SearchFilters",
  "SearchService",
  "flux",
  "PageStore",
  "$route",
  "inits",
  "$window",
  "$timeout",
];

/**
 * Sales Rentals search pages
 *
 * This is the view that serves the search pages,
 * the equivalent of `nsviews/properties.py`
 * `sales`, `rentals`, `_sales_rentals` etc.
 *
 * The results are fetched from `ajax_sales_rentals` by `SearchService`
 *
 * Read docs for each of:
 * - SearchService
 * - SearchFilters
 * - SearchActions
 * - SearchQuery
 *
 * For an initial page load when the server side results are on the page
 * as html, this renders the search form in ng-view,
 * leaves the server rendered results on the page.
 *
 * For subsequent searches (change of search URL, pagination, search params etc.)
 * it removes the server rendered results and displays the new ones up top in the ng-view.
 *
 * When the user changes sales/rentals, regions, neighborhoods etc. using the form
 * or by clicking on any internal links, then the URL changes.
 *
 * This controller, while it is active, intercepts navigation changes and
 * instead calls `SearchActions.fetchForm(url);` which fetches the new form data
 * as JSON.
 *
 * searchResults directive listens for 'search-results' events and updates
 * it's contents whenever new search results arrive.
 *
 * formData is resolved by the route defined above. It is the results of
 * `SearchFormParams.fetchp`
 *
 * Responds to flux actions:
 *  search-query
 *  search-results
 *
 * Dispatches flux events:
 *  remove-server-side-content
 *  search-is-searching
 */
export function SearchPageCtrl(
  $scope,
  formData,
  $location,
  SearchActions,
  SearchQuery,
  SearchFilters,
  SearchService,
  flux,
  PageStore,
  $route,
  inits,
  $window,
  $timeout
) {
  // Errors should not get here.
  // If there is a non-200 then the SearchFormParams will
  // relocate there.
  // Ideally the error should be handled here or in the resolve above,
  // but that $http.get failure handler does not seem to forward the rejection
  if (formData.error) {
    // if is initial page then get
    if (PageStore.isInitialPage()) {
      // Showing server side generated error page
      // any subsequent click should do a full reload
      // otherwise another search page url will get stuck
      // without having rendered the form etc.
      $scope.$on("$locationChangeSuccess", function () {
        $window.location.href = $location.path();
      });
    } else {
      // if not initial then do a reload to get the server side error page
      flux.dispatch("remove-server-side-content", {});
      $window.location.reload();
    }
    return;
  }

  // dispatches flux action
  SearchActions.setFormData(formData);

  // remove page and oby from query
  // and store them in SearchQuery
  const query = $location.search();
  if (query.page) {
    SearchQuery.setPage(query.page);
    delete query.page;
  }
  if (query.oby) {
    SearchQuery.setOby(query.oby);
    delete query.oby;
  }
  SearchActions.setQuery(query);

  // if navigated here from another page
  // then do search
  if (!PageStore.isInitialPage()) {
    flux.dispatch("search-is-searching", true);
    SearchActions.searchIfChanged();
  }

  flux.onChanged(
    "search-query",
    function () {
      SearchQuery.setPage(undefined);
    },
    $scope
  );

  // first page load: server side pagination is there if needed
  $scope.showBottom = !PageStore.isInitialPage();
  // $scope.showTop = false;

  // on arrival of new results, remove any server-side rendered content
  flux.onChanged(
    "search-results",
    function () {
      flux.dispatch("remove-server-side-content", {});
      $scope.showBottom = false;
      $timeout(function () {
        // show bottom pagination only if results are > 10
        // should show only after the body has been compiled and rendered
        const count = SearchService.count();
        $scope.showBottom = count > 10;
        // $scope.showTop = count > 30;
      }, 10);
    },
    $scope
  );

  // a new search form has been loaded
  flux.onChanged(
    "search-form",
    function () {
      flux.dispatch("set-page-store", {
        pageTitle: SearchFilters.pageMeta.pageTitle,
        region: SearchFilters.pageMeta.region,
        innerTitle: "",
      });
    },
    $scope
  );

  // When navigating between different search pages
  // to avoid having a full reload of this controller
  // and re-render
  // intercept any location changes,
  // tell angular that the route is already changed
  // and make flux action calls to fetch the new form
  const lastRoute = $route.current;
  this.onLocationChangeSuccess = function () {
    const route = $route.current && $route.current.$$route;
    const url = $location.path();
    if (route && route.controller === "SearchPageCtrl") {
      // Angular will not reload controller if the route is the same
      // so this aborts the route change
      // maybe event.stopPropagation() does as well
      $route.current = lastRoute;
      // why is this being triggered at all if url is the same ?
      // location.search should not trigger it
      if ((SearchFilters.url() || "").toLowerCase() !== url.toLowerCase()) {
        // unset ?page
        SearchQuery.setPage(undefined);
        // when form data arrives it will trigger a new search
        SearchActions.fetchForm(url);
      }
    }
    if ("activeElement" in document) {
      document.activeElement.blur();
    }
  };

  $scope.$on("$locationChangeSuccess", this.onLocationChangeSuccess);

  // server side payload element holds the space for the form
  // till angular loads
  $scope.$evalAsync(function () {
    angular.element("#search-form-payload").hide();
  });

  inits.controllerWillRender({
    removeServerSideContent: true,
    objectTools: [],
    bodyId: "search-page",
    pageStore: {
      breadcrumbs: [],
      innerTitle: "",
      pageTitle: SearchFilters.pageMeta.pageTitle,
      region: SearchFilters.pageMeta.region,
      insideTop: "",
    },
  });
}
