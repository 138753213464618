const tpl = require("./savedListing.html");

savedListing.$inject = ["SavedListings", "User", "Analytics", "flux", "$log"];

/**
 * saved-listing
 *
 * A user can "save" a listing to their collection (in MyNest) and
 * write their own private notes.
 *
 * This directive displays any existing notes and shows current saved status.
 * Click to edit or write a note, click to toggle saved status.
 **/
export function savedListing(SavedListings, User, Analytics, flux, $log) {
  const hidden = "hide";

  return {
    templateUrl: tpl,
    scope: {
      aptid: "@",
    },
    link: function ($scope, $el) {
      $scope.show = false;

      if (!User.isUser()) {
        return;
      }

      // direct manipulation
      let $liker = $el.find(".sl__liker"),
        $commenter = $el.find(".sl__commenter"),
        $textArea = $el.find("textarea"),
        $saver = $el.find(".sl__saver"),
        $note = $el.find(".sl__note"),
        commenting = false;

      // manipulate the css class of the parent container
      // var parent;
      // if ($attr.updateParent) {
      //   if ($attr.updateParent === 'parent') {
      //     parent = $element.parent();
      //   } else {
      //     parent = $element.parents($attr.updateParent);
      //   }
      // } else {
      //   parent = $element;
      // }

      function comment() {
        commenting = !commenting;
        commentMode(commenting);
      }
      function commentMode(way) {
        $textArea.toggleClass(hidden, !way);
        $saver.toggleClass(hidden, !way);
        $note.toggleClass(hidden, way);
      }

      function save() {
        prSave();
        Analytics.track("saved-comment", {
          apt: $scope.aptid,
        });
        commentMode(false);
        updateNote();
      }
      function like() {
        $scope.listing.rating = $scope.listing.rating ? 0 : 1;
        prSave();
        Analytics.track($scope.listing.rating ? "liked" : "unliked", {
          apt: $scope.aptid,
        });
        updateLiker();
      }
      function updateLiker() {
        // removes sa__liker
        $liker.attr(
          "class",
          $scope.listing.rating ? "fa fa-heart" : "far fa-heart"
        );
      }
      function updateNote() {
        $note.text($scope.listing.note || "");
        $note.toggleClass(hidden, !$scope.listing.note);
      }

      function prSave() {
        return SavedListings.saveApt($scope.aptid, $scope.listing).then(
          ok,
          err
        );
      }
      function ok() {
        commentMode(false);
      }
      function err(e) {
        $log.error(e);
      }

      function update(orFetch) {
        $scope.listing = SavedListings.get($scope.aptid, orFetch) || {};
        $scope.show = true;
        updateLiker();
        updateNote();
        // display the first child
        $el.children().removeClass(hidden);
        // updateParent();
      }
      // function updateParent() {
      //   var sl = $scope.listing;
      //   // parent.toggleClass('liked', sl.liked || sl.saved || sl.noted || false);
      //   // parent.toggleClass('saved', $scope.listing.rating || false);
      //   // parent.toggleClass('noted', $scope.listing.note || false);
      // }

      $liker.on("click touchstart", like);
      $commenter.on("click touchstart", comment);
      $note.on("click touchstart", comment);
      $saver.on("click touchstart", save);

      $scope.listing = {};

      if ($scope.aptid) {
        update(true);
      }

      flux.onChanged(
        "SavedListing",
        function (aptId) {
          if (aptId === String($scope.aptid)) {
            update(false);
          }
        },
        $scope
      );

      //textarea.autosize()
    },
  };
}
