import angular from "angular";
tweetThis.$inject = ["$window"];

/**
 * twitter button
 *
 * <span class="btn btn-mini" tweet-this><i class="fab fa-twitter"></i> Tweet</span>
 *
 * optional attributes:
 *
 * url="https://..."
 * via="nestseekers"
 * text="body of tweet"
 *
 */
export function tweetThis($window) {
  return {
    link: function ($scope, element, attrs) {
      // https://twitter.com/intent/tweet?original_referer=http%3A%2F%2F127.0.0.1%3A8000%2F63400%2F1-centre-market-place-littleitaly-contemporary-novogratzdesigned&text=Contemporary%20Novogratz%5Cu002Ddesigned%20Townhouse%20at%20the%20Axis%20of%20Downtown%5Cu0027s%20Hottest%20Neighborhoods&tw_p=tweetbutton&url=http%3A%2F%2F127.0.0.1%3A8000%2F63400%2F1-centre-market-place-littleitaly-contemporary-novogratzdesigned&via=nestseekers
      element.on("click", function (e) {
        const $ = angular.element,
          url = encodeURIComponent(
            attrs.url ||
              $("link[rel=canonical]").attr("href") ||
              $window.location.href
          ),
          via = attrs.via || "nestseekers",
          // <meta property="og:title"
          text = attrs.text || $window.document.title,
          full =
            "https://twitter.com/intent/tweet?original_referer=" +
            url +
            "&text=" +
            encodeURIComponent(text) +
            "&url=" +
            url +
            "&via=" +
            via;

        e.preventDefault();
        // open in new window
        $window.open(full, "Share a link on Twitter", "width=545,height=540");
      });
    },
  };
}
