import map from 'lodash/map';

import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

const tpl = require("./regionSwitcher.html");

regionSwitcher.$inject = ["SearchQuery", "SearchFilters", "flux"];

/**
 * This displays links to the different region pages.
 *
 * The controller intercepts the route change
 * and loads the new form / changes title etc.
 *
 * note: region is not a query parameter submitted with ?region=
 * rather it is determined by the base URL of the search page.
 *
 * Used on desktop-search
 *
 */
export function regionSwitcher(SearchQuery, SearchFilters, flux) {
  return {
    scope: {},
    replace: true,
    templateUrl: tpl,
    link: function ($scope) {
      let current = getCurrent();

      function getCurrent() {
        return pick(SearchFilters.pageMeta, "region", "srno");
      }

      function update() {
        const globalMeta = SearchFilters.globalMeta;

        $scope.regions = map(
          globalMeta && globalMeta.regions,
          function ([region, desc, isPrimary]) {
            // some srno are not valid for this geo, downgrade to sr
            // so is srno in region
            return {
              url: SearchQuery.buildUrl({
                srno: current.srno,
                geo: region,
                typ: null,
              }),
              desc: desc,
              selected: current.region === region,
              isPrimary,
            };
          }
        );
        // .slice(0, 27);
        // limit regions to 27 so the design doesn't break unexpectedly
      }

      update();

      // when navigating, a new search form will be loaded
      // this updates once that is loaded.
      flux.onChanged(
        "search-form",
        function () {
          const next = getCurrent();
          if (!isEqual(current, next)) {
            current = next;
            update();
          }
        },
        $scope
      );
    },
  };
}
