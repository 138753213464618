import assign from "lodash/assign";
import filter from "lodash/filter";
import isEqual from "lodash/isEqual";

/**
 * SearchQuery
 *
 * Builds the URL for a search and calls SearchService.search if anything has changed.
 *
 * It's sole responsibility is to watch page/oby and SearchFilter and call a search if something has changed.
 *
 * This allows you to:
 * - setPage (page number)
 * - setOby (order by)
 * - set any params in SearchFilters
 *
 * and then this will call SearchService.search only if the URL has changed.
 */
SearchQuery.$inject = [
  "SearchFilters",
  "SearchService",
  "Currency",
  "flux",
];
export function SearchQuery(SearchFilters, SearchService, Currency, flux) {
  class _SearchQuery {
    page = null;
    oby = null;

    constructor() {
      flux.onAction("searchIfChanged", () => {
        this.searchIfChanged();
      });
    }

    setPage(p) {
      this.page = p;
    }

    // set order by
    setOby(o) {
      this.oby = o;
    }

    /**
     * do a search only if the url or params have changed
     * including page, oby, currency
     */
    searchIfChanged() {
      const buildUrl = this.buildUrl();
      const params = this.params();
      const lastUrl = (SearchService.lastUrl || "").toLowerCase();
      if (
        lastUrl !== buildUrl.toLowerCase() ||
        !isEqual(SearchService.lastParams, params)
      ) {
        SearchService.search(buildUrl, params);
      }
    }

    /**
     * compose the base search URL
     * default is the current URL
     *
     * @param {object} changes - srno, region, typ
     *    for changing from the current to a different SR with the same region/typ
     */
    buildUrl(changes) {
      const params = assign(
        {
          srno: SearchFilters.srno().replace("_", "-"),
          geo: SearchFilters.geo(),
          typ: SearchFilters.typ(),
        },
        changes
      );
      return (
        "/" +
        filter([params.srno, params.geo, params.typ], Boolean).join("/") +
        "/"
      );
    }

    params() {
      const p = SearchFilters.getQuery();
      if (this.page) {
        p.page = this.page;
      }
      if (this.oby) {
        p.oby = this.oby;
      }
      const currency = Currency.getCurrency();
      if (currency) {
        p.currency = currency;
      }
      return p;
    }
  }

  return new _SearchQuery();
}
