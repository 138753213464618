/**
 * Analytics for Kissmetrics
 *
 * Kissmetrics is now kind of dead and we've turned
 * off our account.  Since the API key is set to ''
 * this doesn't do anything.
 *
 * The tracking system here can easily be redirected to any
 * other popular event tracking system like Mixpanel or Google.
 *
 * Assumes that _kmq is an array
 * that can be pushed to.
 * if window.analytics === false then it posts to console
 *
 **/
Analytics.$inject = ["$window", "$log", "PageStore"];
export function Analytics($window, $log, PageStore) {
  class _Analytics {
    loaded = false;
    // analytics can be turned off if this is unset.
    // webpack DefinePlugin inserts this
    inactive = true;
    user = null;
    // do not track staff actions
    user_is_staff = false;

    send(msg) {
      $log.debug("analytics:" + JSON.stringify(msg));
      if (!this.user_is_staff) {
        this.load();
        if (!$window._kmq) {
          $window._kmq = [];
        }
        $window._kmq.push(msg);
      }
    }

    track(name, data) {
      this.send(["record", name, data || {}]);
    }

    /**
     * You can use the trackSubmit function to track when a form is submitted.
     * This sets up the element to record an event only when the visitor has
     * submitted the form.
     *
     * @param {string} form - element id of form
     * @param {string} eventName - name of the event to log when form is submitted
     */
    trackForm(form, eventName) {
      this.send(["trackSubmit", form, eventName]);
    }

    page(pageCategory, pageName) {
      this.track(pageCategory, { page: pageName || $window.document.title });
    }

    identify(user) {
      if (user === this.user) {
        return;
      }
      this.user = user;
      this.user_is_staff = user && user.is_staff;

      if (!this.user_is_staff) {
        this.send(["identify", user.uid]);
        this.send(["set", user]);
      }
    }

    load() {
      if (this.loaded) {
        return;
      }
      this.loaded = true;
      $log.debug("_Analytics: " + (this.inactive ? "inactive" : "loading"));
      if (!this.inactive) {
        this.prLoad();
      }
    }

    prLoad() {
      // isn't called because kissmetrics is off
    }

    googlePageView(url) {
      if (!PageStore.isInitialPage()) {
        $log.debug("google pageview", url);
        // google universal analytics
        if ($window.ga) {
          $window.ga("send", "pageview", {
            page: url,
          });
        }
      }
    }
  }

  return new _Analytics();
}
