const tpl = require("./scheduleShowing.html");

scheduleShowing.$inject = ["User", "Analytics"];

/**
 * schedule-showing
 *
 * The primary contact form for making a showing request.
 *
 * <div schedule-showing aptid="{{ apt.id }}" ng-cloak><a href="{% url 'schedule_showing' apt_id=apt.id %}" rel="nofollow" class="btn btn-primary">Schedule a showing</a></div>
 *
 * If JavaScript has failed or there is an error then this form would not appear, but the user would
 * still see a button and link to go to an HTML schedule showing page.
 *
 * Could: save the email/name
 *        get email/name/phone from user
 *        fetch and show any previous contacts
 *        link to requests you have made
 **/
export function scheduleShowing(User, Analytics) {
  return {
    templateUrl: tpl,
    scope: {
      aptid: "@",
    },
    link: function ($scope) {
      $scope.user = {};
      $scope.prefilled = false;
      User.get(function (user) {
        $scope.user = user;
        $scope.prefilled = true;
      });
      $scope.track = function () {
        Analytics.track("schedule-showing", {
          apt: $scope.aptid,
        });
      };
    },
  };
}
