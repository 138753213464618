import angular from "angular";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";
import without from "lodash/without";

/**
 * device
 *
 * Efficiently Detect browser/device functionality.
 */
device.$inject = ["$window", "$rootScope"];
export function device($window, $rootScope) {
  let onResizeCallbacks = [];
  let listening = false;
  let width = null,
    height = null;

  function invokeAll() {
    $rootScope.$apply(function () {
      forEach(onResizeCallbacks, function (fn) {
        fn();
      });
    });
  }

  const api = {
    /**
     * Does device have touch screen ?
     */
    isTouch: function () {
      // only if modernizr is on the page
      // and this only detects if touch api is implemented
      // which is the case for any mac
      return !angular.element("html.no-touch").length;
    },
    /**
     * Register a callback to be called when window resizes
     * or orientation changes.
     *
     * By using this it means there is only one global listener.
     *
     * When the $scope of your directive is destroyed it will automatically
     * unregister the callback.
     */
    onResize: function (fn, $scope) {
      onResizeCallbacks.push(fn);
      if ($scope) {
        $scope.$on("$destroy", function () {
          onResizeCallbacks = without(onResizeCallbacks, fn);
        });
      }
      if (!listening) {
        $window.addEventListener("resize", debounce(invokeAll, 300));
        $window.addEventListener("orientationchange", invokeAll);
        listening = true;
      }
    },
    /**
     * Is it a small screen ?
     *
     * note: 480 is probably outdated
     * 576px is what bootstrap 4 uses.
     * You can change this when you have checked and changed
     * the CSS breakpoints.
     *
     * note, changed it to less than 768
     */
    isPhone: function () {
      const s = api.screenSize();
      return s.width <= 480 || s.height <= 480;
    },
    /**
     * Get width of client
     */
    clientWidth: function () {
      return api.screenSize().width;
    },
    /**
     * Get width/height of client
     *
     * This caches for efficiency rather than accessing the DOM (very expensive ! causes repaints)
     */
    screenSize: function () {
      // should it use $window.innerHeight ?
      if (!width) {
        // safe for all IE versions
        width =
          $window.document.documentElement.clientWidth ||
          $window.document.body.clientWidth;
      }
      if (!height) {
        // safe for all IE versions
        height =
          $window.document.documentElement.clientHeight ||
          $window.document.body.clientHeight;
      }
      return {
        width: width,
        height: height,
      };
    },
  };

  // Clear the width/height cache on resize or rotate
  api.onResize(function () {
    width = null;
    height = null;
  });

  return api;
}
