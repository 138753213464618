import angular from "angular";

/**
 * GoogleMaps service
 *
 * Creates a GoogleMap using our API key and standard customizations.
 *
 */
GoogleMaps.$inject = ["$window", "$q", "$log"];
export function GoogleMaps($window, $q, $log) {
  const api = {
    /**
     *  returns a Promise for window.google.maps
     */
    maps: function (libraries) {
      const q = $q.defer();
      let api_key = __GOOGLE_MAPS_API_KEY__;
      if (api_key) {
        api_key = "&key=" + api_key;
      }
      let url =
        "https://maps.googleapis.com/maps/api/js?v=3" +
        api_key +
        "&callback=mapinit";
      if (libraries) {
        url += "&" + libraries;
      }

      $window.mapinit = function () {
        q.resolve($window.google.maps);
      };

      if ($window.google && $window.google.maps) {
        $window.mapinit();
      } else {
        // async script will not work:
        // angular.element.getScript(url).fail(q.reject);
        // must write it into the document, otherwise:
        // Failed to execute 'write' on 'Document':
        // It isn't possible to write into a document from an
        //  asynchronously-loaded external script unless it
        //  is explicitly opened.
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        document.body.appendChild(script);
      }

      return q.promise;
    },

    /**
     * Make a google map
     *
     * @param g : window.google.maps - as obtained with GoogleMaps.maps()
     * @param elementId:string - Element selector to make the map in.
     * @param opts:object - Extra options
     * @param mode:string - "map" is ROADMAP, else uses HYBRID
     */
    makeMap: function (g, elementId, opts, mode) {
      opts = angular.extend(
        {
          mapTypeId: mode === "map" ? g.MapTypeId.ROADMAP : g.MapTypeId.HYBRID,
          disableDefaultUI: true,
          draggable: true,
          gestureHandling: "cooperative", // 2 finger dragging on mobile
          minZoom: 11,
          zoomControl: true,
          zoomControlOptions: {
            position: g.ControlPosition.TOP_RIGHT,
          },
          streetViewControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [g.MapTypeId.ROADMAP, g.MapTypeId.HYBRID],
            position: g.ControlPosition.RIGHT_BOTTOM,
          },
        },
        opts || {}
      );

      // protect here against errors
      let map;
      try {
        map = new g.Map($window.document.getElementById(elementId), opts);
      } catch (e) {
        $log.error(e);
        $log.error(opts);
      }
      return map;
    },

    /**
     * nestseekers custom map styles
     *
     * only works up till v 3.13
     * after that there is visualRefresh and a new style system
     * https://developers.google.com/maps/documentation/javascript/examples/maptype-styled-simple
     */
    // styles: function() {
    //   return [{
    //       'featureType': 'road.highway',
    //       'stylers': [
    //         {
    //           'weight': 0.7
    //         },
    //         {
    //           'gamma': 0.76
    //         },
    //         {
    //           'saturation': -81
    //         },
    //         {
    //           'lightness': 64
    //         },
    //         {
    //           'visibility': 'simplified'
    //         }
    //       ]
    //     },
    //     {
    //       'featureType': 'road.arterial',
    //       'stylers': [
    //         {
    //           'lightness': 5
    //         },
    //         {
    //           'gamma': 1.19
    //         },
    //         {
    //           'saturation': -75
    //         }
    //       ]
    //     }, {
    //       'featureType': 'landscape.man_made',
    //       'stylers': [
    //         {
    //           'saturation': -50
    //         },
    //         {
    //           'lightness': 56
    //         },
    //         {
    //           'gamma': 0.68
    //         }
    //       ]
    //     },
    //     {
    //       'featureType': 'road.highway',
    //       'elementType': 'geometry.fill',
    //       'stylers': [
    //         {
    //           'visibility': 'on'
    //         },
    //         {
    //           'gamma': 0.64
    //         },
    //         {
    //           'lightness': 84
    //         },
    //         {
    //           'saturation': -77
    //         },
    //         {
    //           'weight': 0.9
    //         },
    //         {
    //           'color': '#eedfdb'
    //         }
    //       ]
    //     }, {
    //       'featureType': 'road.highway',
    //       'elementType': 'geometry.stroke',
    //       'stylers': [
    //         {
    //           'weight': 0.3
    //         }
    //       ]
    //     }, {
    //       'elementType': 'labels.text.stroke',
    //       'stylers': [
    //         {
    //           'weight': 0.5
    //         },
    //         {
    //           'lightness': 40
    //         },
    //         {
    //           'visibility': 'simplified'
    //         }
    //       ]
    //     }, {
    //       'elementType': 'labels.text.fill',
    //       'stylers': [
    //         {
    //           'gamma': 0.13
    //         },
    //         {
    //           'lightness': -14
    //         }
    //       ]
    //     }, {
    //       'featureType': 'water',
    //       'stylers': [
    //         //{ 'gamma': 0.64 },
    //         {
    //           'lightness': -40
    //         },
    //         {
    //           'saturation': -80
    //         }
    //       ]
    //     }];
    // },

    /**
     * mapIcons
     *
     * a dict of the different nestseekers map icons
     *
     */
    icons: function (g) {
      const ics = {},
        iu = "/static/nestseekers/wwwimages/icons/",
        size = new g.Size(32.0, 37.0),
        origin = new g.Point(0, 0),
        anchor = new g.Point(16.0, 34.0); // pointer is 3px from bottom
      // COULD: house townhouse condo open-house

      angular.forEach(["dfault", "liked", "comment"], function (key) {
        ics[key] = {
          normal: {
            url: iu + key + ".png",
            size: size,
            origin: origin,
            anchor: anchor,
          },
          hover: {
            url: iu + key + "-hover.png",
            size: size,
            origin: origin,
            anchor: anchor,
          },
        };
      });
      ics.shadow = new g.MarkerImage(
        iu + "shadow.png",
        new g.Size(51.0, 37.0),
        origin,
        new g.Point(16.0, 51 - 4)
      ); //  icon pointer is 4px from bottom

      return ics;
    },

    /**
     * addApt
     *
     * context:     dict containing: g gmap icons
     * geo:         apt geo json
     * zoomToIt:    boolean
     * onClick:     handler
     * meta:        apt context:  headline icon
     */
    addApt: function (context, geo, zoomToIt, onClick, meta) {
      let marker, latlng;
      if (!geo.lat) {
        // prevents the map from being broken
        // but you shouldnt feed me non-geocoded properties
        return;
      }
      latlng = new context.g.LatLng(geo.lat, geo.lon);
      if (context.maxBounds) {
        context.maxBounds.extend(latlng);
      }
      marker = new context.g.Marker({
        icon: meta.icon || context.icons.dfault.normal,
        map: context.gmap,
        title: meta.headline || geo.address || "",
        // shadow: context.icons.shadow,
        position: latlng,
        clickable: onClick ? true : false,
      });
      // m.setAnimation(g.Animation.DROP);
      if (onClick) {
        context.g.event.addListener(marker, "click", onClick);
      }
      if (zoomToIt) {
        context.gmap.setZoom(geo.zoom);
        context.gmap.panTo(latlng);
      }
      geo.marker = marker;
      return marker;
    },
  };

  return api;
}
