const template = require("./newsletterSubscriptions.html");

newsletterSubscriptions.$inject = ["ajaxviews", "$log"];

export function newsletterSubscriptions(ajaxviews, $log) {
  return {
    scope: {
      data: "=",
    },
    templateUrl: template,
    link: function ($scope) {
      $scope.onSetRegion = function (list) {
        list.subscribed = list.regions.length > 0;
      };

      $scope.submit = function () {
        $scope.error = 0;
        ajaxviews
          .post("update_email_preferences", {
            phash: $scope.data.contact.phash,
            phex: $scope.data.contact.phex,
            lists: $scope.data.lists.map((l) => ({
              key: l.key,
              subscribed: l.subscribed,
              regions: l.regions,
              frequency: l.frequency,
            })),
          })
          .then(
            function () {
              // show confirmation of update
              $scope.success = true;
              $scope.newsubForm.$setPristine();
              $scope.newsubForm.$submitted = false;
            },
            function (err) {
              $log.error(err);
              $scope.error = "An error occurred. Please try again.";
              $scope.newsubForm.$submitted = false;
            }
          );
      };
    },
  };
}
