const tpl = require("./suggestedListings.html");

moreLikeThis.$inject = ["ajaxviews"];

/**
 *
 * show suggested listings
 *
 * always works for a client or anonymous or agent
 *
 **/
export function moreLikeThis(ajaxviews) {
  return {
    templateUrl: tpl,
    scope: {},
    link: function ($scope, $element, $attr) {
      ajaxviews.get(
        "similar_to",
        {
          num: $attr.num || 8,
          id: $attr.id,
        },
        $scope
      );
    },
  };
}
