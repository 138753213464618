/**
 * Convert input[type=checkbox] to nicer non-native controls
 *
 * This is scattered throughout the site, wherever a checkbox is required.
 * especially windows looks bad with native controls.
 */

export function checkboxing() {
  return {
    template:
      '<label class="checkbox" ng-class="{\'selected\':checked}"><i class="far" ng-class="{\'fa-check-square\':checked,\'fa-square\':!checked}"></i> <span ng-transclude></span></label>',
    replace: true,
    transclude: true,
    scope: {},
    link: function ($scope, $element) {
      const input = $element.find("input[type=checkbox]");
      input.hide();
      $scope.checked = input.is(":checked");
      $element.bind("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        $scope.$apply(function () {
          $scope.checked = !$scope.checked;
          input.prop("checked", $scope.checked);
        });
        return false;
      });
    },
  };
}
