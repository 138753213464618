import debounce from 'lodash/debounce';

const template = require("./textSearch.html");

textSearch.$inject = ["SearchFilters", "flux"];

/**
 * Displays the text search input on property search pages
 */
export function textSearch(SearchFilters, flux) {
  const key = "search";
  const ENTER_KEY = 13;
  return {
    scope: {},
    templateUrl: template,
    link: function ($scope) {
      $scope.submit = function () {
        SearchFilters.setFilter(key, $scope.search);
      };

      function init() {
        $scope.search = SearchFilters.getFilter(key);
      }

      // typing is debounced by 1 second then searches
      const setFilter = debounce(() => {
        SearchFilters.setFilter(key, $scope.search);
      }, 1000);

      $scope.$watch(key, setFilter);

      // enter key triggers immediately
      $scope.onKeypress = (event) => {
        if (event.keyCode === ENTER_KEY) {
          SearchFilters.setFilter(key, $scope.search);
        }
        // COULD: cancel a search if one has been called
        // and you resume typing.
      };

      flux.onChanged(
        "search-form",
        function () {
          init();
        },
        $scope
      );
      init();
    },
  };
}
