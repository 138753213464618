import angular from "angular";

searchResultsPagination.$inject = ["flux", "SearchService", "SearchActions"];

/**
 * search-results-pagination
 *
 * Replaces the server rendered pagination
 * with nsPagination which is a fork of bootstrap-ui pagination
 * On search-set-pagination it updates that.
 * On click it calls SearchService-gotoPage
 */
export function searchResultsPagination(flux, SearchService, SearchActions) {
  return {
    template: `<ns-pagination state="state" on-select-page="onSelectPage(page)" ng-class="{'activated': !changing}"></ns-pagination>`,
    link: function ($scope, $element, $attr) {
      // handler passed to nsPagination
      $scope.onSelectPage = function (page) {
        SearchActions.gotoPage(page);
        angular.element("html,body").animate({ scrollTop: 0 }, 350);
        $scope.changing = true;
      };

      function update() {
        const data = SearchService.pagination || {};
        $scope.state = {
          page: data.page || 1,
          count: data.count || 0,
          limit: data.limit || 40,
        };
        $scope.changing = false;
      }
      flux.onChanged("pagination", update, $scope);

      // if these are set then save to SearchResultsPagination
      if ($attr.page) {
        flux.dispatch("setPagination", {
          page: parseInt($attr.page, 10),
          count: parseInt($attr.count, 10),
          displayCount: parseInt($attr.displayCount, 10),
          limit: parseInt($attr.limit, 10),
        });
      } else {
        update();
      }

      // TODO show that its pending
      // search-is-searching
      // search-loading-page
    },
  };
}
