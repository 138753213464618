/**
 *
 * UserStorage
 * ===========
 *
 * Utility for fetching data by key for a specific user (uid)
 * from localstorage
 *
 * If there is no localstorage yet then it will create one
 * for that user.
 *
 * If the user changes (logs out or new one logs in on this browser)
 * then the localstorage is flushed
 *
 * If the application version is updated (as set in the body of the page)
 * then the data is flushed, force a reload from server.
 *
 * Timesout/forces refresh every 600000 * 6 * 12 microseconds.
 *
 * Usage:
 *   us = new UserStorage(app-version-string);
 *   us.fetch("key", remoteFetchFunction, onComplete);
 *
 * so it knows nothing of the remote interface,
 * that is supplied at fetch time.
 *
 * The remoteFetchFunction is not called if the data is available
 * in localstorage and is fresh/valid for this user and app-version-string.
 **/
UserStorage.$inject = ["$window", "$cookies", "CacheFactory", "$log"];
export function UserStorage($window, $cookies, CacheFactory, $log) {
  let version = 0,
    memory = {},
    cache,
    userId = 0,
    checked;

  function storageMode() {
    const testKey = "testStorage",
      storage = $window.sessionStorage;
    if (!storage) {
      return "memory";
    }
    // Safari in private mode will have a broken
    // storage that throws :
    // [Error] QuotaExceededError: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota.
    try {
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return "sessionStorage";
    } catch (e) {
      return "memory";
    }
  }

  if (!(cache = CacheFactory.get("US"))) {
    cache = CacheFactory("US", {
      storageMode: storageMode(),
      maxAge: 600000 * 6 * 12,
      // this option is probably gone now
      // otherwise it writes everything back to storage
      // even on a get
      verifyIntegrity: false,
    });
  }

  const api = {
    setVersion: function (v) {
      // can set application version to clear the cache
      // when a new release is deployed
      version = v;
    },
    isUser: function () {
      return Boolean(api.userId());
    },
    userId: function () {
      if (userId === 0) {
        userId = $cookies.get("uid") || undefined;
      }
      return userId;
    },
    put: function (key, value, options) {
      if (api.isUser()) {
        api.checkUser();
        memory[key] = value;
        try {
          cache.put(key, value, options);
        } catch (e) {
          $log.error(e);
        }
      }
    },
    get: function (key) {
      if (api.isUser()) {
        api.checkUser();
        return api.prGet(key);
      }
      return null;
    },
    prGet: function (key) {
      let val = memory[key];
      if (!val) {
        val = cache.get(key);
      }
      // fallback
      return val;
    },
    clear: function () {
      memory = {};
      try {
        // maybe this is failing due to having to write meta data
        cache.removeAll();
      } catch (e) {
        $log.error(e);
      }
    },
    checkUser: function () {
      if (!checked) {
        let udata;
        checked = 1;
        if (api.isUser()) {
          udata = memory.udata || cache.get("udata");
          if (udata) {
            // possibly invalidate the user
            if (
              udata.uid !== $cookies.get("uid") ||
              udata.sid !== $cookies.get("sid") ||
              udata.version !== version
            ) {
              api.clear();
              api.saveUser();
              return false;
            }
          } else {
            api.saveUser();
          }
        }
      }
    },
    saveUser: function () {
      const key = "udata",
        val = {
          sid: $cookies.get("sid"),
          uid: $cookies.get("uid"),
          version: version,
        };
      memory[key] = val;
      try {
        cache.put(key, val);
      } catch (e) {
        $log.error(e);
      }
    },
    /**
     * fetch from cache
     * calling remove fetcher function
     * if value is null
     *
     * @param  {string}   key
     * @param  {function} fetcher  fetches from remote resource
     * @param  {function} didFetch callback with result
     */
    fetch: function (key, fetcher, didFetch) {
      let v;
      if (api.isUser()) {
        api.checkUser();
        v = api.prGet(key);
        if (v) {
          didFetch(v);
        } else {
          api.fetchRemote(key, fetcher, didFetch);
        }
      }
    },
    fetchRemote: function (key, fetcher, didFetch) {
      fetcher(function (result) {
        api.put(key, result);
        didFetch(result);
      });
    },
  };

  return api;
}
