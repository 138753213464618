const tpl = require("./inviteToSignup.html");

inviteToSignup.$inject = ["User", "ModalLogin"];

/**
 * invite-to-signup
 *
 * If not a user, show a link to trigger signup modal
 * and launch the modal login when clicked.
 *
 * Used in the top nav-sheet menu.
 */
export function inviteToSignup(User, ModalLogin) {
  return {
    scope: {},
    templateUrl: tpl,
    link: function ($scope) {
      $scope.user = User.isUser();
      $scope.modalLogin = ModalLogin.open;
    },
  };
}
