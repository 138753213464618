/**
 * If $location does not match any route then redirect browser to that url
 */
OtherwiseCtrl.$inject = ["$location", "$window", "PageStore"];
export function OtherwiseCtrl($location, $window, PageStore) {
  if (!PageStore.isInitialPage()) {
    const base = $window.$("base").attr("href") || "/";
    if (base.match(/^\/admin\//)) {
      $window.location.href = $location.absUrl();
    } else {
      $window.location.href = $location.url();
    }
  }
}
