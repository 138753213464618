import clone from 'lodash/clone';

import constant from 'lodash/constant';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import keys from 'lodash/keys';

const template = require("./checkboxFilter.html");

/**
  * multiple checkboxes
  *
  * a factory to make a directive
  * initially it shows a button.
  * on clicking that,
  * opens a panel to check multiple elements
  *
  * @param {string} key The slug/key for the checkbox field group
  * @param {string|function} anyPhrase The text shown when nothing is selected: All The Things
  * @param {string|function} typesPhrase The text shown when more than one item is selected.

  * @returns {function} the definition object for the directive
  */
checkboxFilter.$inject = ["SearchFilters", "SearchActions",  "flux"];
export function checkboxFilter(SearchFilters, SearchActions,  flux) {
  return function (key, anyPhrase, typesPhrase) {
    if (isString(anyPhrase)) {
      anyPhrase = constant(anyPhrase);
    }
    if (isString(typesPhrase)) {
      typesPhrase = constant(typesPhrase);
    }

    return {
      scope: {},
      templateUrl: template,
      link: function ($scope, $element) {
        function description() {
          const vals = keys($scope.optionsState),
            numSelected = vals.length;
          // list one type or "X Types"
          if (numSelected === 0) {
            return anyPhrase();
          }
          if (numSelected === 1) {
            return SearchFilters.labelForOption(key, vals[0]);
          }
          return "" + numSelected + " " + typesPhrase();
        }

        function isSelected() {
          return !isEmpty($scope.optionsState);
        }

        function commit() {
          SearchFilters.setOptions(key, $scope.optionsState);
        }

        /**
         * The only explicit open/close actions are here
         */
        $scope.toggle = function ($event) {
          $event.stopPropagation();
          if ($scope.opened) {
            commit();
            SearchActions.openFilter();
            return;
          }
          loadState();
          SearchActions.openFilter(key);
        };

        function setOpened(bool) {
          if (bool !== $scope.opened) {
            const $btn = $element.find(".btn-group");
            const $dropdownMenu = $element.find(".dropdown-menu");
            $scope.opened = bool;
            // ng-class is failing to set opened if there are multiple directives
            // on component
            // https://github.com/angular/angular.js/issues/10811
            // "no plans to fix this."
            // so I'm modifying DOM directly
            $btn.toggleClass("open", bool);
            $dropdownMenu.toggleClass("open", bool);
          }
        }

        $scope.prefix = key;
        $scope.set = function (pk, boo) {
          if (boo) {
            $scope.optionsState[pk] = true;
          } else if ($scope.optionsState[pk]) {
            delete $scope.optionsState[pk];
          }
          update();
        };

        // one value is set
        $scope.isSet = function (pk) {
          return Boolean($scope.optionsState[pk]);
          // SearchFilters.optionIsSet(key, pk);
        };

        function init() {
          $scope.options = clone(SearchFilters.formOptions(key) || []);
          // show two columns because there are more than 10 options
          $scope.sizeClass = $scope.options.length > 10 ? "double" : "";
          loadState();
        }

        function loadState() {
          // does this not potentially erase what you are working on ??
          // it only sets that when you close
          // so only init this data when you first open
          $scope.optionsState = clone(SearchFilters.query[key] || {});
        }

        function update() {
          $scope.description = description();
          $scope.isSelected = isSelected();
        }

        flux.onChanged(
          "search-query",
          function (what) {
            if ((what || key) === key) {
              update();
            }
          },
          $scope
        );

        flux.onChanged(
          "search-form",
          function () {
            init();
            update();
          },
          $scope
        );

        flux.onChanged(
          "SearchFilters-open",
          function (openedKey) {
            // somebody else opened
            const itsMe = openedKey === key;

            if ($scope.opened && !itsMe) {
              // I am open and somebody else is now opening
              // so commit changes
              commit();
            }

            setOpened(itsMe);
          },
          $scope
        );

        init();
        update();
      },
    };
  };
}
