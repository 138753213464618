import assign from 'lodash/assign';

import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';

ajaxviews.$inject = ["$http"];

/**
 * services/ajax-views.js
 *
 * A service to get or post to django views built with the @ajaxview decorator.
 * This allows making a django view and referring to it just by name here.
 *
 * This allows sending and receiving objects back and forth.
 * post with data and get data as a response
 */
export function ajaxviews($http) {
  return {
    /**
     * @param {String} viewname        - the django view decorated with @ajaxview
     * @param {Object}  params         - parameters passed as data to the view
     * @param {Object|function} $scope - callback or $scope to receive the data
     * @returns {Promise}
     */
    get: function (viewname, params, $scope) {
      const promise = $http.get(__BACKEND__ + "/ajax/view/" + viewname, {
        params: params,
      });
      if (isFunction($scope)) {
        return promise.then(function (response) {
          $scope(response.data);
        });
      } else if (isObject($scope)) {
        return promise.then(function (response) {
          assign($scope, response.data);
        });
      }
      // return the promise for response, get .data from that yourself
      return promise;
    },
    post: function (viewname, data) {
      return $http.post(__BACKEND__ + "/ajax/view/" + viewname, data);
    },
  };
}
