import shuffle from 'lodash/shuffle';

import extend from 'lodash/extend';
import random from 'lodash/random';

const template = require("./featuredCommercial.html");
featuredCommercial.$inject = ["$window", "$interval",  "device"];

/**
 * featured commercial properties rotator
 *
 * This is temporarily hacked to display
 * Gold Coast LI listings.
 *
 * The directive itself doesn't
 */
export function featuredCommercial($window, $interval,  device) {
  return {
    scope: {},
    templateUrl: template,
    replace: true,
    link: function ($scope, $element, $attr) {
      if (device.clientWidth() < 480) {
        return;
      }

      const data = shuffle(JSON.parse($attr.data));
      if (!data.length) {
        return;
      }

      $element.on("mouseover", function () {
        $scope.hovering = true;
      });
      $element.on("mouseout", function () {
        $scope.hovering = false;
      });

      function next(i) {
        extend($scope, data[i % data.length]);
      }
      next(data.length - 1);

      const cycle = $interval(next, random(6000, 8000));

      $scope.$on("$destroy", function () {
        $interval.cancel(cycle);
      });
    },
  };
}
