import includes from 'lodash/includes';

const template = require("./modalPriceFilter.html");

modalPriceFilter.$inject = ["SearchFilters", "priceUtils",  "flux"];

/**
 * modal-price-filter
 * select price min and max in the mobile/modal dialog
 */

export function modalPriceFilter(SearchFilters, priceUtils,  flux) {
  return {
    scope: true,
    replace: true,
    templateUrl: template,
    link: function ($scope) {
      let minKey = "min_price",
        maxKey = "max_price",
        anyPhrase = "Price",
        key = "price";

      function update() {
        const currencySymbol = SearchFilters.currencySymbol();
        $scope.minVal = SearchFilters.getFilter(minKey);
        $scope.maxVal = SearchFilters.getFilter(maxKey);
        // not undefined, 0 or ''
        $scope.isSet = $scope.minVal || $scope.maxVal;
        anyPhrase = SearchFilters.srno() === "sales" ? "Price" : "Rent";
        if (currencySymbol !== "$") {
          anyPhrase += " (" + currencySymbol + ")";
        }
        if ($scope.opened || $scope.isSet) {
          $scope.title = priceUtils.description(
            $scope.minVal,
            $scope.maxVal,
            currencySymbol,
            anyPhrase
          );
        } else {
          // closed and not set
          $scope.title = anyPhrase;
        }
        $scope.minOptions = priceUtils.minPriceOptions($scope.minVal || 0);
        $scope.maxOptions = priceUtils
          .maxPriceOptions($scope.maxVal || "")
          .slice(1);
      }

      $scope.opened = false;
      $scope.open = function () {
        $scope.opened = !$scope.opened;
        if ($scope.opened) {
          $scope.openFilter(key);
        }
      };

      $scope.select = function (k, val) {
        // min or max may change the other one
        const sets = {};
        if (val === 0) {
          val = "";
        }
        sets[k] = val;
        if (k === minKey) {
          if (val > $scope.maxVal) {
            sets[maxKey] = ""; // no limit
          }
        } else {
          if (val !== "" && $scope.minVal > val) {
            sets[minKey] = "";
          }
        }
        SearchFilters.setFilters(sets);
      };

      // if (device.isTouch()) {
      //   $element.on('touchstart touchend touchmove', function(e) {
      //     var target;
      //     if (e.type === 'touchmove') {
      //       // modal backdrop:
      //       // target = document.elementFromPoint(e.clientX, e.clientY);
      //       // e.originalEvent
      //     } else {
      //       target = e.target;
      //     }
      //     // touchmove target is where it started
      //     // document.elementFromPoint(event.clientX, event.clientY);
      //     // then select key, val
      //     // or evaluate ng-click
      //     console.log(e.type, target, e);
      //   });
      // }

      flux.onChanged(
        "search-query",
        function (what) {
          if (includes([undefined, minKey, maxKey], what)) {
            update();
          }
        },
        $scope
      );
      flux.onChanged("search-form", update, $scope);
      flux.onChanged("Currency", update, $scope);

      $scope.$on("filter-did-open", function (e, openingKey) {
        if (key !== openingKey) {
          $scope.opened = false;
        }
      });

      update();
    },
  };
}
