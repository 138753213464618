import angular from "angular";
import extend from 'lodash/extend';
import filter from 'lodash/filter';
import map from 'lodash/map';
import random from 'lodash/random';
import shuffle from 'lodash/shuffle';

const template = require("./ultraLuxurySoldPropertiesRow.html");

ultraLuxurySoldPropertiesRow.$inject = ["$interval",  "device"];

/**
 * ultraLuxurySold properties rotator
 */
export function ultraLuxurySoldPropertiesRow($interval,  device) {
  return {
    scope: {},
    templateUrl: template,
    replace: true,
    link: function ($scope, $element, $attr) {
      if (device.clientWidth() < 480) {
        return;
      }
      // if non-mobile
      // if and when in view
      let titles = {
          manhattan: "MANHATTAN",
          hamptons: "HAMPTONS",
          "los-angeles": "BEVERLY HILLS",
        },
        slugs = ["manhattan", "hamptons", "los-angeles"],
        cycles = {
          manhattan: 0,
          hamptons: 0,
          "los-angeles": 0,
        },
        cycle;
      // var fake = "http://ca9bbf64368a6a984987-3c8c31c4bcd7270c51f697d3e25919d1.r97.cf2.rackcdn.com/0NZlrNrSa62pgi.jpg";
      const regions = JSON.parse($attr.data);
      $scope.regions = filter(
        map(slugs, function (slug) {
          let region = regions[slug],
            obj = {
              slug: slug,
              title: titles[slug] + " FEATURED PROPERTIES",
            };
          if (region) {
            region = shuffle(region);
            regions[slug] = region;
            return extend(obj, makeData(region[0]));
          } else {
            return null;
          }
        }),
        angular.isObject
      );

      function makeData(obj) {
        return {
          subtitle: obj.hline,
          url: obj.url,
          src: obj.src,
          price: obj.price,
        };
      }

      $element.on("mouseover", function () {
        $scope.hovering = true;
      });
      $element.on("mouseout", function () {
        $scope.hovering = false;
      });

      cycle = $interval(function () {
        let ri = random(0, 2),
          rslug = slugs[ri],
          region = regions[rslug],
          ai;
        if (region && region.length && !$scope.hovering) {
          ai = cycles[rslug] + 1;
          if (ai >= region.length) {
            ai = 0;
          }
          cycles[rslug] = ai;
          extend($scope.regions[ri], makeData(region[ai]));
        }
      }, random(6000, 7000));

      $scope.$on("$destroy", function () {
        $interval.cancel(cycle);
      });
    },
  };
}
