/**
 * Very simple flux implementation.
 *
 * dispatch
 * onAction
 * onChanged
 * changed
 *
 * Flux allows you to keep a single store for state that any part of the
 * application can access, subscribe to updates on specific parts of the global state,
 * and can call actions to update parts of the state.
 */
import without from 'lodash/without';
import forEach from "lodash/forEach";

flux.$inject = ["$log"];
export function flux($log) {
  const actionHandlers = {},
    storeWatchers = {};

  return {
    /**
     * Dispatch an action to all registered handlers.
     *
     * Generally an action creator function calls this
     * or you can call directly from your view.
     *
     * This is the how you make changes to the global state
     *
     *     flux.dispatch("search-is-searching", true);
     *
     * @param {String} type   - name of action
     * @param {Object} action - data for the action request
     */
    dispatch: function (type, action) {
      $log.debug("flux.dispatch:", type, action);
      // dispatch to each handler that is registered for this action type
      forEach(actionHandlers[type], function (ah) {
        ah(action);
      });
    },
    /**
     * Register a handler that will respond to an action.
     *
     * @param {String}   type - name of action
     * @param {function} handler
     */
    onAction: function (type, handler) {
      let ahs = actionHandlers[type];
      if (!ahs) {
        actionHandlers[type] = ahs = [];
      }
      ahs.push(handler);
    },
    /**
     * Directives register a function to be called
     * when a store has changed.
     *
     * @param {String}   name - the name of the store
     * @param {function} handler
     * @param {Object}   scope - will unregister itself when $scope is destroyed
     */
    onChanged: function (name, handler, scope) {
      let changeHandlers = storeWatchers[name];
      if (!changeHandlers) {
        storeWatchers[name] = changeHandlers = [];
      }
      changeHandlers.push(handler);
      scope.$on("$destroy", function () {
        storeWatchers[name] = without(storeWatchers[name], handler);
      });
    },
    /**
     * Store announces that it has changed
     *
     * @param {String} name - name of Store
     * may be any string though as long as the handler uses the same convention
     * eg. SearchFilters may broadcast both 'search-query' 'search-form'
     */
    changed: function (name, what) {
      forEach(storeWatchers[name], function (handler) {
        handler(what);
      });
    },
  };
}
