/**
 * SearchService
 *
 * This fetches pages of search results from the server and broadcast the results via flux.
 *
 * The display elements will respond to the flux events and update forms and results.
 *
 * Responds to flux actions:
 *    setPagination
 *
 * Dispatches flux events:
 *   track-page
 *
 * Broadcasts flux changed notifications:
 *    search-results
 *    search-is-searching
 *    pagination
 */
import bind from "lodash/bind";
import extend from "lodash/extend";

SearchService.$inject = [
  "$location",
  "$log",
  "$http",
  "$q",
  "flux",
  "lruCache",
  "$timeout",
];
export function SearchService(
  $location,
  $log,
  $http,
  $q,
  flux,
  lruCache,
  $timeout,
) {
  class _SearchService {
    previousRequest = null;

    lastUrl = null; // the full url of the last search
    lastParams = null;

    page = 1; // location.search()['page']
    pagination = null;
    results = null;
    autoSearch = true;

    constructor() {
      flux.onAction("setPagination", (data) => this.setPagination(data));
    }

    /**
     * Fetch results from server
     *
     * @param url {string} - The URL is the same as a normal http request:
     *
     * https://www.nestseekers.com/Sales/los-angeles/
     * https://www.nestseekers.com/Sales/westwood/
     * https://www.nestseekers.com/sales/manhattan/vacant-lot
     *
     * but it is requested with ?format=json and results are returned as JSON
     *
     * @param params {object} - GET query string params
     *
     * Any search parameters as well as page, oby, currency
     *
     *  ?bedrooms=1&price=750000_20000000
     *  ?bedrooms=1&oby=low&currency=EUR&price=750000_20000000
     *  ?bedrooms=1&oby=low&currency=EUR&price=750000_20000000&page=3
     *
     * If there is already a fetch in progress then this will cancel that
     * and call the new one.
     *
     * Results (rows of listings) are set to this.results and a flux changed
     * message is broadcast for the display components to come and get the update.
     */
    search(url, params) {
      $log.debug("search", url, params);

      $location.search(params);
      this.setIsSearching(true);

      if (this.previousRequest) {
        this.previousRequest.resolve();
      }
      this.previousRequest = $q.defer();

      const request = $http.get(url, {
        params: extend({}, params, { format: "json" }),
        timeout: this.previousRequest,
        cache: lruCache,
      });
      request.success((data) => {
        this.lastUrl = url;
        this.lastParams = params;
        $timeout(() => {
          this.responseHandler(data);
        });
      });
      request.error(bind(this.errorHandler, this));
    }

    responseHandler(data) {
      this.previousRequest = null;
      this.setIsSearching(false);
      this.results = data;
      this.setPagination(data);
      flux.changed("search-results");
      flux.dispatch("track-page", $location.url());
    }

    setPagination(data) {
      this.pagination = {
        count: data.count || 0,
        displayCount: data.display_count || data.count || 0,
        vowCount: data.vow_count || 0,
        page: data.page_num || 1,
        limit: data.limit || 40,
      };
      this.page = this.pagination.page_num;

      flux.changed("pagination");
    }

    count() {
      return (this.pagination && this.pagination.count) || 0;
    }

    setIsSearching(bool) {
      this.searching = bool;
      flux.changed("search-is-searching", bool);
    }

    errorHandler(data) {
      this.previousRequest = null;
      this.setIsSearching(false);
      $log.error(data);
    }
  }

  return new _SearchService();
}
