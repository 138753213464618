/**
 * Datetime formatting
 *
 * <datetime time="{{ datetime }}"></datetime>
 *
 * displays:
 *
 *   Date
 *   3 days ago
 *
 * Assumes a unix timestamp in your local timezone.
 * If an agent does something in LA timezone,
 * then the manager in LA will see the time correctly.
 */

import { format as formatTime } from "fecha";
import { format as timeago } from "timeago.js";

export function datetime() {
  return {
    template:
      '<div ng-show="time" class="datetime">{{ formatted }} <div class="quiet fine">(<span>{{ ago }}</span>)</div></div>',
    replace: true,
    scope: {
      time: "@",
    },
    link: function ($scope) {
      $scope.$watch("time", function () {
        if ($scope.time) {
          const time = new Date($scope.time * 1000);
          $scope.formatted = formatTime(time, "ddd, MMM Do YYYY, h:mm a");
          $scope.ago = timeago(time);
        }
      });
    },
  };
}
