import angular from "angular";
/**
 * For normal HTTP forms (not angular SPA), prevents a double submit
 * and adds ng-submitted to the form just like angular does to its ng-form elements.
 **/

export function noDoubleSubmit() {
  return {
    link: function ($scope, $element) {
      $element.bind("submit", function ($event) {
        if (!$scope.submitting) {
          const form = angular.element($element);
          form.addClass("ng-submitted");
          $scope.submitting = true;
        } else {
          $event.preventDefault();
        }
      });
    },
  };
}
