serverSideContent.$inject = ["flux", "PageStore"];

/**
 * server-side-content
 *
 * This directive is added to the results that the web server
 * includes on the page. After the first load using ajax
 * it is removed from the DOM.
 */
export function serverSideContent(flux, PageStore) {
    return {
        scope: {},
        link: function ($scope, $element) {
            let removed = false;

            flux.onChanged(
                "server-side-content",
                function () {
                    if (!removed) {
                        // need to tell all child scopes that that they are being destroyed
                        $scope.$destroy();
                        $element.remove();
                        removed = true;
                    }
                },
                $scope
            );

            function setLoading(bool) {
                if (!removed) {
                    // fade the element as pending
                    $scope.loading = bool;
                    $element.toggleClass("loading", bool);
                }
            }

            flux.onChanged("search-is-searching", setLoading, $scope);

            // and on pending location change
            $scope.$on("$locationChangeStart", function (e, newUrl) {
                // but not initial page
                // or nav by # anchors on page
                const anchor = newUrl.indexOf("#") !== -1;
                if (!(PageStore.isInitialPage() || anchor)) {
                    setLoading(true);
                }
            });
        },
    };
}