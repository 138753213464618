openMenu.$inject = ["flux", "Menu", "$timeout"];

/**
 * open-menu
 *
 * added to the primary navbar menu links
 * to open the menu on click, tap or hover
 */
export function openMenu(flux, Menu, $timeout) {
  return {
    link: function ($scope, $element, $attrs) {
      const section = $attrs.openMenu;
      let active = false;
      let over = false;

      $element.on("mouseover", function (event) {
        Menu.mouseIsActive(event);
        if (Menu.isOpen()) {
          // if already open then use it to switch the menu to this section
          $scope.$apply(open);
        } else {
          over = true;
          $timeout(function () {
            if (over && !Menu.isOpen()) {
              Menu.mouseIsActive(event);
              open();
            }
          }, 350);
        }
      });

      $element.on("mouseleave", function () {
        over = false;
      });

      $element.on("click touchstart", function (event) {
        if (Menu.isOpen()) {
          // close it and let the event complete
          $scope.$apply(() => flux.dispatch("closeMenu"));
        } else {
          event.preventDefault();
          Menu.mouseIsActive(event);
          $scope.$apply(open);
        }
      });

      $element.on("mousemove", function (event) {
        over = true;
        // tells navSheet that the user is still mousing in the area
        // and not to shut it
        Menu.mouseIsActive(event);
      });

      function open() {
        flux.dispatch("openMenu", { menu: "main", section: section });
      }

      flux.onChanged(
        "Menu",
        function (state) {
          const newActive = state.section === section;
          if (active !== newActive) {
            $element.toggleClass("active", newActive);
            active = newActive;
          }
        },
        $scope
      );
    },
  };
}
