import angular from "angular";
import forEach from "lodash/forEach";
import isUndefined from "lodash/isUndefined";
import includes from "lodash/includes";


dualPriceFilter.$inject = [
  "SearchFilters",
  "$timeout",
  "priceUtils",
  "SearchActions",
  "Currency",
  "flux",
];
export function dualPriceFilter(
  SearchFilters,
  $timeout,
  priceUtils,
  SearchActions,
  Currency,
  flux
) {
  /**
   * A factory to make a filter that has a min and max val
   * selected by one dropdown after another.
   *
   * template is always dualPriceFilter.html
   *
   * @param {string} key The slug/key for the checkbox field group
   * @param {string} anyPhrase The text shown when nothing is selected: All The Things
   *
   * @returns {function} a link function for the directive
   */
  return function (key, anyPhrase) {
    const min_key = "min_" + key,
      max_key = "max_" + key;

    return function ($scope, $element) {
      function updateDescription() {
        $scope.description = priceUtils.description(
          $scope.minVal,
          $scope.maxVal,
          Currency.currencySymbol(),
          anyPhrase
        );
        $scope.isSelected = $scope.minVal || $scope.maxVal;
      }

      function updateOptions() {
        if ($scope.which === "a") {
          $scope.options = priceUtils.minPriceOptions(
            parseInt($scope.minVal, 10)
          );
        } else if ($scope.which === "b") {
          $scope.options = priceUtils.maxPriceOptions(
            parseInt($scope.maxVal, 10),
            $scope.minVal || 0
          );
        }
      }

      $scope.open = function () {
        if ($scope.opened) {
          return close();
        }

        openSide("a");
        $timeout(function () {
          $element.find(".min-val").focus();
        }, 0);
        SearchActions.openFilter(key);
      };

      function openSide(which) {
        $scope.which = which;
        updateDescription();
        updateOptions();
      }

      function setOpened(bool) {
        if ($scope.opened !== bool) {
          $scope.opened = bool;
          $element.find(".dropdown-menu").toggleClass("open", bool);
          updateDescription();
          // if (!bool) {
          //   // what is setting this to .open ???
          //   const dp = $element.find('dual-price-filter');
          //   dp.toggleClass('open', false);
          //   console.log('unset it', dp, dp.attr('class'));
          // }
        }
      }

      function commit() {
        SearchFilters.setFilters({
          [min_key]: $scope.minVal,
          [max_key]: $scope.maxVal,
        });
      }

      function close() {
        if ($scope.opened) {
          SearchActions.openFilter();
        }
      }

      function closeAndCommit() {
        commit();
        close();
      }

      $scope.select = function (val, $event) {
        $event.stopPropagation();
        if ($scope.which === "a") {
          $scope.minVal = val;
          $scope.minDesc = priceUtils.currency(val, undefined);
          $timeout(function () {
            openSide("b");
            $element.find(".max-val").focus();
          }, 0);
        } else {
          $scope.maxVal = val;
          $scope.maxDesc = priceUtils.currency(val, undefined);
          closeAndCommit();
        }
      };

      /**
       * find the currently selected index
       * may be undefined if value is typed in and doesn't match
       */
      function optionIndexForValue(val) {
        let found;
        val = String(parseInt(val, 10));
        forEach($scope.options, function (obj, i) {
          if (obj.pk === val) {
            found = i;
          }
        });
        return found;
      }

      /**
       * increment or decrement the current option
       */
      function incdec(direction) {
        let a = $scope.which === "a",
          val = a ? $scope.minVal : $scope.maxVal,
          index = optionIndexForValue(val);
        // if no selection yet, then you can increment
        if (val === "") {
          if (direction > 0) {
            index = 0;
          } else {
            return;
          }
        }
        if (!isUndefined(index)) {
          index = index + direction;
          if (index >= 0 && index < $scope.options.length) {
            val = $scope.options[index].pk;
            if (a) {
              $scope.minVal = val;
              $scope.minDesc = priceUtils.currency(val, undefined);
            } else {
              $scope.maxVal = val;
              $scope.maxDesc = priceUtils.currency(val, undefined);
            }
            $scope.options[index - direction].selected = false;
            $scope.options[index].selected = true;
          }
        }
      }

      /**
       * open the options if they aren't already
       */
      $scope.onFocus = function (which) {
        if ($scope.which !== which) {
          $timeout(function () {
            openSide(which);
          }, 1);
        }
      };

      /**
       * on every keyup, watch for k/m/b and expand
       * it to ,000
       */
      $scope.keyup = function ($event, prefix) {
        const $input = angular.element($event.target);
        const val = priceUtils.expandKMB($input.val());
        if (!isUndefined(val)) {
          $scope[prefix + "Val"] = val;
          $scope[prefix + "Desc"] = priceUtils.currency(val, undefined);
        }
      };
      $scope.keydown = function (keyCode) {
        switch (keyCode) {
          // tab
          case 9:
            if ($scope.which === "b") {
              return closeAndCommit();
            }
            break;
          // shift-tab
          case 16:
            if ($scope.which === "a") {
              return closeAndCommit();
            }
            break;
          // enter
          case 13:
            if ($scope.which === "a") {
              openSide("b");
            } else {
              closeAndCommit();
            }
            return;
          case 38: // up arrow
            return incdec(-1);
          case 40: // down arrow
            return incdec(1);
        }
      };
      /**
       * when tabbing or clicking away
       * clean the number and set it
       */
      $scope.onBlur = function (which, $event) {
        let $input = angular.element($event.target),
          val = priceUtils.cleanNum($input.val());
        if (!val && which === "a") {
          val = 0;
        }
        $scope[(which === "a" ? "min" : "max") + "Val"] = val;
        $scope[(which === "a" ? "min" : "max") + "Desc"] = priceUtils.currency(
          val,
          undefined
        );
        // updateDescription();
      };

      function update() {
        const opts = SearchFilters.formOptions(min_key);
        // do not show the widget at all if there are no options
        // for this region or srno type
        $scope.showFilter = opts && opts.length;
        if ($scope.showFilter) {
          $scope.minVal = SearchFilters.getFilter(min_key);
          $scope.maxVal = SearchFilters.getFilter(max_key);
          $scope.minDesc = priceUtils.currency($scope.minVal, undefined);
          $scope.maxDesc = priceUtils.currency($scope.maxVal, undefined);

          updateDescription();
          updateOptions();
        }
      }

      $scope.opened = false;
      flux.onChanged(
        "search-query",
        function (what) {
          if (includes([undefined, null, min_key, max_key], what)) {
            update();
          }
        },
        $scope
      );
      flux.onChanged("search-form", update, $scope);
      flux.onChanged("Currency", updateDescription, $scope);

      flux.onChanged(
        "SearchFilters-open",
        function (openedKey) {
          // somebody else opened
          const itsMe = openedKey === key;

          if ($scope.opened && !itsMe) {
            // I am open and somebody else is now opening
            // so commit changes
            commit();
          }
          setOpened(itsMe);
        },
        $scope
      );

      update();
    };
  };
}
