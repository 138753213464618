import isString from 'lodash/isString';

import flatten from 'lodash/flatten';
import map from 'lodash/map';

const template = require("./breadcrumbs.html");

/**
 * breadcrumbs
 *
 * Loads them from json in the html markup
 * highlights the currently active when the route changes
 */
breadcrumbs.$inject = [ "$location", "flux", "PageStore"];

export function breadcrumbs( $location, flux, PageStore) {
  return {
    scope: {
      crumbs: "=",
    },
    templateUrl: template,
    replace: true,
    link: function ($scope) {
      $scope.url = $location.path();
      $scope.show = 0;

      function convert(crumb, klass) {
        if (isString(crumb)) {
          return {
            title: crumb,
            url: "",
            klass: klass,
          };
        } else if (Array.isArray(crumb)) {
          // if first one is array then its a sub nav list
          if (Array.isArray(crumb[0])) {
            // and the separator is different
            return crumb.map( (c) => convert(c, "fa fa-caret-right"));
          }
          return {
            url: crumb[0],
            title: crumb[1],
            klass: klass,
          };
        }
      }

      function load(crumbs) {
        $scope.breadcrumbs = flatten(
          map(crumbs, (c) => convert(c, "fa fa-angle-right"))
        );
        $scope.show = $scope.breadcrumbs.length;
      }

      load($scope.crumbs || []);

      // on route change set the url
      $scope.$on("$routeChangeSuccess", function () {
        $scope.url = $location.path();
      });

      flux.onChanged(
        "PageStore",
        function (key) {
          if (key === "breadcrumbs") {
            load(PageStore.get("breadcrumbs"));
          }
        },
        $scope
      );
    },
  };
}
