import angular from "angular";

/**
 * imgTools
 *
 *   loadImg
 *   resizeImg
 */
imgTools.$inject = ["$window"];
export function imgTools($window) {
  const $ = $window.$ || angular.element;
  const api = {
    /**
     * loadImg
     *
     * makes sure an image has been loaded and cached in the browser
     * and will be able to be displayed instantly and without error
     *
     * @param  {string}   url     url to load image from
     * @param  {object}   context object to store width, height and loaded state
     * @param  {Function} cb      callback after image is loaded
     * @param  {Function} onError error handler
     */
    loadImg: function (url, context, cb, onError) {
      const img = new Image(),
        $img = $(img);

      $img
        .load(function () {
          // find and set it on the thumb
          context.ar = this.width / this.height;
          context.loaded = true;
          context.width = this.width;
          context.height = this.height;
          cb($img);
        })
        .error(onError);

      $img.attr("src", url);
      // this needs to be the actual <img> object
      // trigger for IE if needs to
      if (img.complete || img.complete === undefined) {
        const src = img.src;
        // webkit hack from http://groups.google.com/group/jquery-dev/browse_thread/thread/eee6ab7b2da50e1f
        // data uri bypasses webkit log warning (thx doug jones)
        img.src =
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        img.src = src;
        // $img.trigger('load')
      }
    },
    /**
     * set $img and $container css to the ideal width/height
     *
     * given aspect_ratio and the image's context object
     *
     * @param  {$element}    $img          an img or iframe (youtube)
     * @param  {$element}         $container    the parent container
     * @param  {Object} context   context dict holding meta data for image
     */
    resizeImg: function (
      $img,
      $container,
      context,
      ideal_ar,
      containerWidth,
      containerHeight,
      fullSize
    ) {
      let h,
        neww,
        d,
        ar = (context && context.ar) || ideal_ar,
        ideal_height,
        image_height,
        max_height,
        top = 0,
        is_vid = $img.is("iframe"),
        is_pdf = $img.is(".pdf");

      // aspect ratio of gallery on page
      // and they should be server scaled
      // not full images
      // or parse all images and get best aspect ratio
      ideal_height = parseInt(containerWidth / ideal_ar, 10);
      max_height = ideal_height;

      if (fullSize) {
        // height should be max
        max_height = containerHeight;

        if (is_vid || is_pdf) {
          ideal_height = max_height;
        } else {
          image_height = context.height;
          ideal_height = Math.min(image_height, max_height);
        }
      }

      h = ideal_height;
      neww = ideal_height * ar;
      if (neww > containerWidth) {
        neww = containerWidth;
        h = containerWidth / ar;
      }
      // if image is shorter then position it in the middle
      if (h < max_height) {
        top = (max_height - h) / 2.0;
      }

      if (is_vid) {
        $container.css({
          height: max_height,
          width: containerWidth,
        });
        // leave pdf icon alone
      } else {
        d = {
          height: h,
          width: Math.ceil(neww),
          top: top,
          left: (containerWidth - neww) / 2,
        };

        if (is_pdf) {
          d["line-height"] = "" + h + "px";
          d.width = containerWidth;
        }
        $container.css({ height: max_height });
        $img.css(d);
      }
    },
  };
  return api;
}
