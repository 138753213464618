searchProgressBar.$inject = ["flux", "SearchService"];

/**
 * search-progress-bar
 *
 * Purple vibrating bar that shows while you are loading results.
 */
export function searchProgressBar(flux, SearchService) {
  return {
    scope: {},
    template:
      '<div class="m-progress" ng-class="{hiding: !searching}">' +
      '<div class="indeterminate"></div>' +
      "</div>",
    link: function ($scope) {
      function update() {
        $scope.searching = SearchService.searching;
      }
      flux.onChanged("search-is-searching", update, $scope);
      update();
    },
  };
}
