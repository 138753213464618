/**
 * SearchActions
 *
 * directives on search pages should use this service to call all actions search related actions.
 *
 * This invokes other async services (fetching forms and results)
 * and calls flux to dispatch those actions.
 *
 * Stores (Services) register action handlers and update themselves.
 * and use flux to notify changed.
 *
 * directives handle changed events and update themselves.
 *
 * Dispatches flux events:
 *   fetchForm
 *   setFormData
 *   setQuery
 *   searchIfChanged
 *   openFilter
 */
SearchActions.$inject = ["SearchQuery", "flux"];
export function SearchActions(SearchQuery, flux) {
  return {
    fetchForm: function (url) {
      flux.dispatch("fetchForm", url);
    },
    setFormData: function (data) {
      if (!data.error) {
        flux.dispatch("setFormData", data);
      }
    },
    setQuery: function (query) {
      flux.dispatch("setQuery", query);
    },
    changeRegion: function (region) {
      const url = SearchQuery.buildUrl({ geo: region });
      flux.dispatch("fetchForm", url);
    },
    changeSr: function (sr) {
      const url = SearchQuery.buildUrl({ srno: sr });
      flux.dispatch("fetchForm", url);
    },
    searchIfChanged: function () {
      flux.dispatch("searchIfChanged");
    },
    gotoPage: function (page) {
      SearchQuery.setPage(page);
      flux.dispatch("searchIfChanged");
    },
    openFilter: function (key) {
      flux.dispatch("openFilter", { key: key });
    },
  };
}
